import React, {Component, Fragment} from 'react';
import PanelFunctions from '../../containers/Panel/Functions';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

class PanelComponent extends Component {
    render() {
        return (
            <Fragment>
                <div className={'panel-header panel-' + this.props.position}>
                    <span className={'panel-title'}>{this.props.title}</span>
                    <i className="fa fa-window-close" onClick={() => this.props.closePanel(this.props.id, false)}/>
                </div>

                {this.props.children}
            </Fragment>
        );
    }
}

PanelComponent.propTypes = {
    title: PropTypes.string,
    closePanel: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    position: PropTypes.string.isRequired,
    children: PropTypes.object.isRequired,
};

export default connect(null, PanelFunctions)(PanelComponent);
