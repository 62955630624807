import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

import ReservationAccordionSlotsScreen from './ReservationAccordionSlotsScreen';
import {
    DATE_DISPLAY_FORMAT,
    TIME_DISPLAY_FORMAT_HOUR
} from '../../utils/consts';

class ReservationAccordionHourScreen extends React.Component {
    render() {
        const {availableSlots, planning, product, slotDay} = this.props;

        const formattedHour = moment(slotDay, DATE_DISPLAY_FORMAT).add(availableSlots.slotHour, 'h').format(TIME_DISPLAY_FORMAT_HOUR);
        const availableSlotsString = availableSlots.availableSlots === 1 ? 'slot disponible': 'slots disponibles' ;

        return (
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        {`${formattedHour}h (${availableSlots.availableSlots} ${availableSlotsString})`}
                        {
                            availableSlots.hasFriends && (availableSlots.planning_id === planning.id) &&
                            <i className={'fa fa-user ml-2'} />
                        }
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ReservationAccordionSlotsScreen availableSlots={availableSlots}
                                                     planning={planning}
                                                     product={product}
                                                     slotDay={slotDay}
                    />
                </AccordionItemPanel>
            </AccordionItem>
        );
    }
}

ReservationAccordionHourScreen.propTypes = {
    availableSlots  : PropTypes.object.isRequired,
    planning        : PropTypes.object.isRequired,
    product         : PropTypes.object.isRequired,
    slotDay         : PropTypes.string.isRequired,
};

export default ReservationAccordionHourScreen;