import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';

import SlotReservationScreen from '../../screens/Reservation/SlotReservation';
import {admin} from "../../routing/route";

const mapStateToProps = (state) => {
    return {
        customers: state.Customer.view.list.content,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        bookSlot: (params) => {
            ownProps.history.push({
                pathname: admin.reservationStep1.path,
                state: {params: params}
            });
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SlotReservationScreen));