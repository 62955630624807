// Library
import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import PropTypes from 'prop-types';
// CSS
import 'react-toastify/dist/ReactToastify.css';
// Constants
import {GET_ME_INTERVAL_DELAY} from '../../utils/consts';
import {admin} from '../../routing/route';
// Containers
import Header from '../../containers/Header/HeaderContainer';
import NotFoundScreen from '../../screens/NotFound/NotFoundScreen';
import Loader from '../../components/Loader/LoaderComponent';
import PanelManagerContainer from '../../containers/Panel/PanelManagerContainer';
import SidebarContainer from '../../containers/Sidebar/SidebarContainer';

import CustomerContainer from '../../containers/Customer/CustomerContainer';
import FriendContainer from '../../containers/Friend/FriendContainer';
import ReservationContainer from '../../containers/Reservation/ReservationContainer';
import ReservationStep1Container from '../../containers/Reservation/ReservationStep1Container';
import ReservationConfirmationComponent from '../../screens/Reservation/ReservationConfirmationScreen';

class AdminScreen extends Component {
    constructor(props) {
        super(props);

        this.getMeInterval();

        props.load();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (
            <div className="app">
                <Header/>

                <div className="app-body">
                    <SidebarContainer/>

                    <main className="main">
                        <PanelManagerContainer/>

                        {this._renderView()}
                    </main>
                </div>
            </div>
        );
    }

    _renderView() {
        const {loading} = this.props;

        if (loading) {
            return (
                <Loader />
            );
        }

        return (
            <Switch>
                <Route exact path='/' name={admin.reservation.name} component={ReservationContainer}/>
                <Route exact path={admin.customer.path} name={admin.customer.name} component={CustomerContainer}/>
                <Route exact path={admin.friend.path} name={admin.friend.name} component={FriendContainer}/>
                <Route exact path={admin.reservation.path} name={admin.reservation.name} component={ReservationContainer}/>
                <Route exact path={admin.reservationStep1.path} name={admin.reservationStep1.name} component={ReservationStep1Container}/>
                <Route exact path={admin.reservationConfirmation.path} name={admin.reservationConfirmation.name} component={ReservationConfirmationComponent}/>
                <Route path="" component={NotFoundScreen}/>
            </Switch>
        );
    }

    getMeInterval() {
        this.interval = setInterval(() => this.props.load(), GET_ME_INTERVAL_DELAY);
    }
}

AdminScreen.propTypes = {
    loading: PropTypes.bool,
    load: PropTypes.func,
};

export default AdminScreen;