import {MODELS} from './consts';
import _ from 'lodash';
import moment from 'moment';

/**
 * @param value
 * @returns {boolean}
 */
export function isTrue(value) {
    if (typeof (value) === 'string') {
        value = value.trim().toLowerCase();
    }

    switch (value) {
        case true:
        case 'true':
        case 1:
        case '1':
        case 'on':
        case 'yes':
            return true;
        default:
            return false;
    }
}

export function getSelectOptionData(model) {
    let label = '', icon = '', className = (model.__model) ? _.snakeCase(model.__model) : '';

    switch (model.__model) {
        case MODELS.CUSTOMER:
            label = `${model.first_name} ${model.last_name}`;
            icon = 'fas fa-user';
            break;
        default:
            label = model.label;
    }

    return {label, icon, className};
}

export function _formatSearchParameters(parameters) {
    if (!parameters) {
        return {};
    }

    let formattedParams = {};
    let filter = [];

    if (typeof parameters.filter !== 'undefined') {
        if (Array.isArray(parameters.filter)) {
            filter = parameters.filter;
        } else if (typeof parameters.filter === 'object') {
            filter.push(parameters.filter);
        }
    }

    Object
        .keys(parameters)
        .forEach((parameter) => {
            if (typeof this.searchFields !== 'undefined' && typeof this.searchFields[parameter] !== 'undefined') {
                const parameterType = this.searchFields[parameter];

                let criterion = {
                    column: parameter,
                    value: parameters[parameter],
                };

                switch (parameterType) {
                    case 'integer':
                        criterion.operator = (Array.isArray(parameters[parameter])) ? 'IN' : '=';
                        break;
                    case 'boolean':
                        criterion.operator = '=';
                        criterion.value = isTrue(parameters[parameter]) ? 1 : 0;
                        break;
                    case 'string':
                    default:
                        criterion.operator = 'LIKE';
                        break;
                }

                filter.push(criterion);
            } else if (parameter !== 'filter') {
                formattedParams[parameter] = parameters[parameter];
            }
        });

    if (filter.length !== 0) {
        formattedParams.filter = filter;
    }

    return formattedParams;
}

export function filterByLanguage(translations, availableLanguage) {
    return translations.filter((translation) => {
        return availableLanguage.indexOf(translation.locale) >= 0;
    });
}

export function orderTranslations(translations, orderedLanguage) {
    return translations.sort((firstTranslation, secondTranslation) => {
        return orderedLanguage.indexOf(firstTranslation) - orderedLanguage.indexOf(secondTranslation);
    });
}

export function filterAndOrderTranslations(translations, languageList) {
    return orderTranslations(filterByLanguage(translations, languageList), languageList);
}

/**
 * Convert a string to studly caps case.
 *
 * @param string
 */
export const studlyCase = string => _.upperFirst(_.camelCase(string));

/**
 * Binary safe string comparison of the first n characters
 *
 * @param a
 * @param b
 * @param n
 * @returns {number}
 */
export const strncmp = (a, b, n) => {
    a = a.substring(0, n);
    b = b.substring(0, n);

    return (
        (a === b)
            ? 0
            : (
                ( a > b )
                    ? 1
                    : -1
            )
    );
};

/**
 * Returns the first argument if it's an array.
 */
export const arrayOrArgs = args => Array.isArray(args[0]) ? args[0] : args;

/**
 * Omits the key-value pairs corresponding to the given keys from an object.
 * @see https://github.com/30-seconds/30-seconds-of-code#omit
 */
export const omit = (obj, ...arr) => {
    arr = arrayOrArgs(arr);

    return Object.keys(obj)
        .filter(k => !arr.includes(k))
        .reduce((acc, key) => ((acc[key] = obj[key]), acc), {});
};

/**
 * Iterates over all own properties of an object, running a callback for each one.
 * @see https://github.com/30-seconds/30-seconds-of-code#forown
 */
export const forOwn = (obj, fn) => Object.keys(obj).forEach(key => fn(obj[key], key, obj));

Object.defineProperty(Array.prototype, 'findItemHavingPropertyEqualTo', {
    value: function(property, value) {
        let element = this.find(item => item[property] === value);

        if (typeof element === 'undefined') {
            return null;
        }

        return element;
    }
});

Object.defineProperty(Object.prototype, 'setValueFromKey', {
    value: function(property, value) {
        const levels = property.split('.');
        let currentLevel = this;
        let i = 0;

        while (i < levels.length-1) {
            if(! currentLevel[levels[i]]) {
                currentLevel[levels[i]] = {};
            }

            currentLevel = currentLevel[levels[i]];
            i++;
        }

        if (currentLevel[levels[levels.length-1]] instanceof moment) {
            value = moment(value);
        } else if (Number.isInteger(currentLevel[levels[levels.length-1]])) {
            value = parseInt(value, 10);
        }

        currentLevel[levels[levels.length-1]] = value;

        return this;
    }
});

Object.defineProperty(Object.prototype, 'getValueFromKey', {
    value: function(property) {
        const levels = property.split('.');
        let currentLevel = this;
        let i = 0;

        while (i < levels.length-1) {
            if(! currentLevel.hasOwnProperty(levels[i]) && ! currentLevel[levels[i]]) {
                return null;
            }

            currentLevel = currentLevel[levels[i]];
            i++;
        }

        return currentLevel[levels[levels.length-1]];
    }
});
