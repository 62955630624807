import PlanningEntity from '../models/entity/PlanningEntity';
import {ACTION_CONST} from '../actions/PlanningAction';
import ModelReducer from './ModelReducer';

const INITIAL_STATE = {
    data: {},
    view: {
        list: {
            content: [],
            error: null,
            loading: true,
            page: 1,
        },
        create: {
            error: null,
            loading: false,
        },
        detail: {
            error: null,
            loading: false,
        },
    }
};

class PlanningReducer extends ModelReducer {}

export default new PlanningReducer(INITIAL_STATE, ACTION_CONST, PlanningEntity, ['list']).reduce;