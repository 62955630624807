import ModelApi   from './ModelApi';
import {HTTP_GET} from '../utils/api';

class ReservationApi extends ModelApi {
    getAvailableSlots(parameters) {
        return this.callApi(HTTP_GET, `${this.prefix}/available-slots`, parameters);
    }

    getCustomerPrice(customerId, parameters) {
        return this.callApi(HTTP_GET, `${this.prefix}/${customerId}/price`, parameters);
    }
}

export default new ReservationApi('reservation');
