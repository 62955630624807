import Manager from './Manager';
import { actions } from '../../actions/UserAction';

class UserManager extends Manager {
    constructor(actions) {
        super(actions);

        this.searchFields = {
            ...this.searchFields,
            login: 'string',
            full_name: 'string',
        };
    }

    getMe(callback) {
        return this.dispatch(
            this.actions.getMe.INIT,
            () => this.actions.getMe.ACTION({}),
            (content) => this.actions.getMe.SUCCESS(content, {}),
            (error) => this.actions.getMe.FAILURE(error, {}),
            callback,
        );
    }
}

export default new UserManager(actions);

