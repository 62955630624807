import CourseEntity from '../models/entity/CourseEntity';
import {ACTION_CONST} from '../actions/CourseAction';
import ModelReducer from './ModelReducer';

const INITIAL_STATE = {
    data: {},
    view: {
        list: {
            content: [],
            error: null,
            loading: true,
            page: 1,
        },
        create: {
            error: null,
            loading: false,
        },
        detail: {
            error: null,
            loading: false,
        },
    }
};

class CourseReducer extends ModelReducer {}

export default new CourseReducer(INITIAL_STATE, ACTION_CONST, CourseEntity, ['list']).reduce;