import DispatcherService from '../../services/Dispatcher/DispatcherService';
import {_formatSearchParameters} from '../../utils/functions';

export default class Manager {
    /**
     *
     * @param actions
     */
    constructor(actions) {
        if (!actions ||
            (
                !actions.get &&
                !actions.create &&
                !actions.update &&
                !actions.delete
            )
        ) {
            throw 'Invalid actions';
        }

        this.actions = actions;

        this.parameters = {
            getAll: {
                limit: 0
            }
        };

        this.searchFields = {
            id: 'integer',
            label: 'string',
            created_at: 'date',
            updated_at: 'date',
        };

        this._formatSearchParameters = _formatSearchParameters.bind(this);
    }

    /**
     *
     * @param actionInit
     * @param action
     * @param actionSuccess
     * @param actionFailure
     * @param callback
     */
    dispatch(actionInit, action, actionSuccess, actionFailure, callback) {
        DispatcherService.classicDispatch(actionInit, action, actionSuccess, actionFailure, callback);
    }

    /**
     *
     * @returns {*}
     */
    getAll() {
        return this.search(this.parameters.getAll);
    }

    /**
     *
     * @param parameters
     * @param callback
     */
    search(parameters, callback) {
        return this.dispatch(
            this.actions.get.INIT,
            () => this.actions.get.ACTION(this._formatSearchParameters(parameters)),
            (content) => this.actions.get.SUCCESS(content, parameters),
            (error) => this.actions.get.FAILURE(error, parameters),
            callback
        );
    }

    /**
     *
     * @param parameters
     * @param callback
     */
    create(parameters, callback) {
        return this.dispatch(
            this.actions.create.INIT,
            () => this.actions.create.ACTION(parameters),
            (content) => this.actions.create.SUCCESS(content, parameters),
            (error) => this.actions.create.FAILURE(error, parameters),
            callback
        );
    }

    /**
     *
     * @param id
     * @param parameters
     * @param callback
     */
    update(id, parameters, callback) {
        return this.dispatch(
            this.actions.update.INIT,
            () => this.actions.update.ACTION({...parameters, id: id}),
            (content) => this.actions.update.SUCCESS(content, parameters),
            (error) => this.actions.update.FAILURE(error, parameters),
            callback
        );
    }

    /**
     *
     * @param id
     * @param parameters
     * @param callback
     */
    delete(id, parameters, callback) {
        return this.dispatch(
            this.actions.delete.INIT,
            () => this.actions.delete.ACTION({...parameters, id: id}),
            () => this.actions.delete.SUCCESS({id}, parameters),
            (error) => this.actions.delete.FAILURE(error, parameters),
            callback
        );
    }
}