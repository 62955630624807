import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormGroup, Input, Label, Card, CardBody, CardHeader,} from 'reactstrap';

import {getSpanError}   from '../functions';
import LaddaButton, {SLIDE_LEFT} from 'react-ladda';

const FIELD_CURRENT_PASSWORD    = 'current_password';
const FIELD_NEW_PASSWORD        = 'password';
const FIELD_CONFIRM_PASSWORD    = 'password_confirmation';

class CustomerScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            parameters: {
                current_password: '',
                password: '',
                password_confirmation: '',
            },
            formErrors: {},
        };

        this._handleChange = this._handleChange.bind(this);
        this._handleSubmit = this._handleSubmit.bind(this);
    }

    _checkForm() {
        const parameters = this._getFormValues();
        let errors = {};

        if (!this._validatePassword(parameters.password)) {
            errors.password = 'format';
        }

        if (parameters.password
            && (!this._validatePasswordRepeat(parameters.password, parameters.password_confirmation))) {
            errors.password_confirmation = true;
        }

        this.setState({ formErrors: errors});
        return Object.keys(errors).length === 0;
    }

    /**
     *
     * @param key
     * @param value
     * @param callback
     */
    _handleChange(key, value, callback = undefined) {
        let parameters = {...this.state.parameters};

        parameters.setValueFromKey(key, value);

        this.setState({parameters}, callback);
    }

    _handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        if (this._checkForm(this._getFormValues())) {
            const {current_password, password} = this._getFormValues();
            this.props.updatePassword({old_password: current_password, password: password});
        }
    }

    /**
     *
     * @param attribute
     * @returns {boolean}
     * @protected
     */
    _hasError(attribute) {
        return this.state.formErrors[attribute];
    }

    /**
     *
     * @return {*}
     */
    _getFormValues() {
        const {current_password, password, password_confirmation} = this.state.parameters;

        return {
            current_password, password, password_confirmation
        };
    }

    /**
     *
     * @param password
     * @returns {boolean}
     * @private
     */
    _validatePassword(password){
        let re = /^((?=.*?[A-Z])|(?=.*?[a-z]))(?=.*?[0-9]).{8,}$/;
        return re.test(String(password));
    }

    /**
     *
     * @param value1
     * @param value2
     * @returns {boolean}
     * @private
     */
    _validatePasswordRepeat(value1, value2) {
        return value1 === value2;
    }

    render() {
        const {isPending} = this.props;
        const {current_password, password, password_confirmation} = this.state.parameters;

        return (
            <Card>
                <CardHeader>
                    <h2>{'Mon profil'}</h2>
                </CardHeader>
                <CardBody className={'d-flex flex-column'}>
                    <div className={'mb-2 constraints'}>
                        <div>{'Contraintes :'}</div>
                        <div>{'-8 caractères minimum'}</div>
                        <div>{'-1 lettre majuscule'}</div>
                        <div>{'-1 lettre minuscule'}</div>
                    </div>
                    <div className={'col-sm-10'}>
                        <form id={'update-password-form'} onSubmit={this._handleSubmit}>
                            <FormGroup row={true}>
                                <Label htmlFor={FIELD_CURRENT_PASSWORD} className="col-sm-3 col-form-label">{'Mot de passe courant *'}</Label>
                                <div className="col-sm-3">
                                    <Input id={FIELD_CURRENT_PASSWORD} name={FIELD_CURRENT_PASSWORD} type="password" required
                                           onChange={(e) => this._handleChange(FIELD_CURRENT_PASSWORD, e.target.value)}
                                           defaultValue={current_password}/>
                                    {this._hasError(FIELD_CURRENT_PASSWORD) && getSpanError()}
                                </div>
                            </FormGroup>

                            <FormGroup row={true}>
                                <Label htmlFor={FIELD_NEW_PASSWORD} className="col-sm-3 col-form-label">{'Nouveau mot de passe *'}</Label>
                                <div className="col-sm-3">
                                    <Input id={FIELD_NEW_PASSWORD} name={FIELD_NEW_PASSWORD} type="password" required
                                           onChange={(e) => this._handleChange(FIELD_NEW_PASSWORD, e.target.value)}
                                           defaultValue={password}/>
                                    {this._hasError(FIELD_NEW_PASSWORD) === 'format' && getSpanError('Format de mot passe invalide')}
                                    {this._hasError(FIELD_NEW_PASSWORD) === 'empty' && getSpanError()}
                                </div>
                            </FormGroup>

                            <FormGroup row={true}>
                                <Label htmlFor={FIELD_CONFIRM_PASSWORD} className="col-sm-3 col-form-label">{'Confirmation nouveau mot de passe *'}</Label>
                                <div className="col-sm-3">
                                    <Input id={FIELD_CONFIRM_PASSWORD} name={FIELD_CONFIRM_PASSWORD} type="password" required
                                           onChange={(e) => this._handleChange(FIELD_CONFIRM_PASSWORD, e.target.value)}
                                           defaultValue={password_confirmation}/>
                                    {
                                        this._hasError(FIELD_CONFIRM_PASSWORD) &&
                                        <div className={'error'}>{'Les nouveaux mots de passe doivent être identique'}</div>
                                    }
                                </div>
                            </FormGroup>
                            <LaddaButton
                                className={'btn btn-primary btn-ladda px-4'}
                                loading={isPending}
                                data-style={SLIDE_LEFT}
                                form={'update-password-form'}
                                type={'submit'}
                            >
                                {'Modifier le mot de passe'}
                            </LaddaButton>
                        </form>
                    </div>
                </CardBody>
            </Card>
        );
    }
}

CustomerScreen.propTypes = {
    updatePassword  : PropTypes.func.isRequired,
};

export default CustomerScreen;