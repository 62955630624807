class ReducerFunctions {
    /**
     *
     * @param state
     * @param section
     * @returns {{[p: string]: *}}
     */
    defaultInit(state, section) {
        state.view[section].error = null;
        state.view[section].loading = true;

        return {...state};
    }

    /**
     *
     * @param state
     * @returns {{[p: string]: *}}
     */
    defaultProcessed(state) {
        return {...state};
    }

    /**
     *
     * @param state
     * @param payload
     * @param section
     * @returns {{[p: string]: *}}
     */
    defaultFailure(state, payload, section) {
        state.view[section].error = payload.reason ? payload.reason : payload.message;
        state.view[section].loading = false;

        return {...state};
    }

    /**
     *
     * @param state
     * @param section
     * @returns {{}}
     */
    oneInit(state, section) {
        return this.defaultInit(state, section);
    }

    /**
     *
     * @param state
     * @param section
     * @param action
     * @returns {{}}
     */
    oneProcessed(state, section, action) {
        return this.defaultProcessed(state, section, action);
    }

    /**
     *
     * @param state
     * @param payload
     * @param section
     * @returns {{}}
     */
    oneFailure(state, payload, section) {
        return this.defaultFailure(state, payload, section);
    }

    /**
     *
     * @param state
     * @param payload
     * @param section
     * @param entityFunction
     * @returns {{[p: string]: *}}
     */
    oneSuccess(state, payload, section, entityFunction) {
        state.data = {...state.data};

        if (entityFunction) {
            payload = new entityFunction(payload);
        }
        if (!state.data[payload.id]) {
            state.data[payload.id] = {};
        }
        Object.assign(state.data[payload.id], payload);
        state.view[section].content = state.data[payload.id];

        state.view[section].error = null;
        state.view[section].loading = false;

        return {...state};
    }

    /**
     *
     * @param state
     * @param section
     * @returns {{}}
     */
    getInit(state, section) {
        if (!state.view[section]) {
            state.view[section] = {
                content: [],
                pagination: {
                    page: 1,
                    per_page: 20
                },
            };
        } else {
            state.view[section].content = [];
            state.view[section].pagination = {
                page: 1,
                per_page: 20
            };
        }

        return this.defaultInit(state, section);
    }

    /**
     *
     * @param state
     * @param section
     * @param action
     * @returns {{}}
     */
    getProcessed(state, section, action) {
        return this.defaultProcessed(state, section, action);
    }

    /**
     *
     * @param state
     * @param payload
     * @param section
     * @param entityFunction
     * @returns {{[p: string]: *}}
     */
    getSuccess(state, payload, section, entityFunction) {
        state.data = {...state.data};

        for (let i = 0; i < payload.data.length; ++i) {
            if (entityFunction) {
                try {
                    payload.data[i] = new entityFunction(payload.data[i]);
                } catch (e) {
                    console.log(e);
                }
            }
            if (!state.data[payload.data[i].id]) {
                state.data[payload.data[i].id] = {};
            }
            Object.assign(state.data[payload.data[i].id], payload.data[i]);
            state.view[section].content.push(state.data[payload.data[i].id]);
        }

        state.view[section].content = [...state.view[section].content];
        state.view[section].pagination = {
            page: payload.current_page,
            per_page: payload.per_page,
            total: payload.total,
        };

        state.view[section].loading = false;
        state.view[section].error = null;

        return {...state};
    }

    /**
     *
     * @param state
     * @param payload
     * @param section
     * @param action
     * @returns {{}}
     */
    getFailure(state, payload, section, action) {
        return this.defaultFailure(state, payload, section, action);
    }

    /**
     *
     * @param state
     * @param section
     * @param action
     * @returns {{}}
     */
    createInit(state, section, action) {
        return this.defaultInit(state, section, action);
    }

    /**
     *
     * @param state
     * @param section
     * @param action
     * @returns {{}}
     */
    createProcessed(state, section, action) {
        return this.defaultProcessed(state, section, action);
    }

    /**
     *
     * @param state
     * @param payload
     * @param section
     * @param entityFunction
     * @returns {{[p: string]: *}}
     */
    createSuccess(state, payload, section, entityFunction) {
        state.view[section].error = null;

        if (entityFunction) {
            payload = new entityFunction(payload);
        }

        if (!state.data[payload.id]) {
            state.data[payload.id] = {};
        }

        state.data[payload.id] = Object.assign(state.data[payload.id], payload);

        if (typeof state.view[section].content !== 'undefined' && !Array.isArray(state.view[section].content)) {
            state.view[section].content = state.data[payload.id];
        }

        state.view[section].loading = false;

        return {...state};
    }

    /**
     *
     * @param state
     * @param payload
     * @param section
     * @param action
     * @returns {{}}
     */
    createFailure(state, payload, section, action) {
        return this.defaultFailure(state, payload, section, action);
    }

    /**
     *
     * @param state
     * @param section
     * @param action
     * @returns {{}}
     */
    updateInit(state, section, action) {
        return this.defaultInit(state, section, action);
    }

    /**
     *
     * @param state
     * @param section
     * @param action
     * @returns {{}}
     */
    updateProcessed(state, section, action) {
        return this.defaultProcessed(state, section, action);
    }

    /**
     *
     * @param state
     * @param payload
     * @param section
     * @param sectionToReload
     * @param entityFunction
     * @returns {{[p: string]: *}}
     */
    updateSuccess(state, payload, section, sectionToReload, entityFunction) {
        state.view[section].error = null;

        if (!state.data[payload.id]) {
            state.data[payload.id] = {};
        }

        if (entityFunction) {
            Object.assign(state.data[payload.id], new entityFunction(payload));
        }

        if (sectionToReload && Array.isArray(sectionToReload) && sectionToReload.length) {
            sectionToReload.map((oneSection) => {
                state.view[oneSection].content = [...state.view[oneSection].content];
            });
        }

        state.view[section].loading = false;

        return {...state};
    }

    /**
     *
     * @param state
     * @param payload
     * @param section
     * @param action
     * @returns {{}}
     */
    updateFailure(state, payload, section, action) {
        return this.defaultFailure(state, payload, section, action);
    }

    /**
     *
     * @param state
     * @param section
     * @returns {{}}
     */
    deleteInit(state, section) {
        return this.defaultInit(state, section);
    }

    /**
     *
     * @param state
     * @returns {{}}
     */
    deleteAction(state) {
        return this.defaultProcessed(state);
    }

    /**
     *
     * @param state
     * @param payload
     * @param section
     * @param sectionToReload
     * @returns {{[p: string]: *}}
     */
    deleteSuccess(state, payload, section, sectionToReload) {
        state.view[section].error = null;

        if (!Array.isArray(sectionToReload) && sectionToReload) {
            sectionToReload = [sectionToReload];
        }

        if (sectionToReload.length) {
            sectionToReload.forEach((oneSection) => {
                const index = state.view[oneSection].content.findIndex((oneContent) => {
                    return oneContent.id === payload.id;
                });

                if (index > -1) {
                    let tempState = [...state.view[oneSection].content];

                    tempState.splice(index, 1);

                    state.view[oneSection].content = tempState;
                }
            });
        }

        if (state.data[payload.id]) {
            delete state.data[payload.id];
        }

        state.view[section].loading = false;

        return {...state};
    }

    /**
     *
     * @param state
     * @param payload
     * @param section
     * @returns {{}}
     */
    deleteFailure(state, payload, section) {
        return this.defaultFailure(state, payload, section);
    }
}

export default new ReducerFunctions();