import Manager from './Manager';
import { actions } from '../../actions/ConfigAction';

class ConfigManager extends Manager {
    constructor(actions) {
        super(actions);

        this.searchFields = {
            ...this.searchFields,
            label: 'string',
        };
    }
}

export default new ConfigManager(actions);

