import ReservationEntity from '../models/entity/ReservationEntity';
import {ACTION_CONST} from '../actions/ReservationAction';
import ModelReducer from './ModelReducer';
import ReducerFunctions from './functions';

const INITIAL_STATE = {
    data: {},
    view: {
        list: {
            content: [],
            error: null,
            loading: true,
            page: 1,
        },
        create: {
            error: null,
            loading: false,
        },
        detail: {
            error: null,
            loading: false,
        },
        customerPrice: {
            content: [],
            error: null,
            loading: false,
        }
    }
};

class ReservationReducer extends ModelReducer {
    reduce(state = this.initialState, action = {}) {
        state = super.reduce(state, action);

        switch(action.type) {
            case this.consts.getAvailableSlots.INIT: {
                return ReducerFunctions.getInit(state, 'list');
            }

            case this.consts.getAvailableSlots.ACTION: {
                return ReducerFunctions.getProcessed(state, 'list', action);
            }

            case this.consts.getAvailableSlots.SUCCESS: {
                state.data = {...state.data};

                Object.assign(state.data, action.payload);
                state.view['list'].content = state.data.data;

                state.view['list'].error = null;
                state.view['list'].loading = false;

                return {...state};
            }

            case this.consts.getAvailableSlots.FAILURE: {
                return ReducerFunctions.getFailure(state, action.payload, 'list');
            }

            case this.consts.getCustomerPrice.INIT: {
                return ReducerFunctions.oneInit(state, 'customerPrice');
            }

            case this.consts.getCustomerPrice.ACTION: {
                return ReducerFunctions.oneProcessed(state, 'customerPrice', action);
            }

            case this.consts.getCustomerPrice.SUCCESS: {
                return ReducerFunctions.oneSuccess(state, action.payload, 'customerPrice', ReservationEntity);
            }

            case this.consts.getCustomerPrice.FAILURE: {
                return ReducerFunctions.getFailure(state, action.payload, 'customerPrice');
            }

            default: {
                return state;
            }
        }
    }
}
export default new ReservationReducer(INITIAL_STATE, ACTION_CONST, ReservationEntity, ['list']).reduce;