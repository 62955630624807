import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import ReservationAccordionSlot from './ReservationAccordionSlotScreen';

class ReservationAccordionSlotsScreen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeSlot:   null,
        };

        this._setActiveButton = this._setActiveButton.bind(this);
    }

    _setActiveButton(slot) {
        this.setState({activeSlot: slot});
    }

    _getAccordionItems(availableSlots) {
        const {planning, product, slotDay} = this.props;
        const {activeSlot} = this.state;

        return availableSlots.slots
            .sort((a, b) => moment(a.slotHour).diff(moment(b.slotHour)))
            .reduce((acc, slot) => {
                const isActive = activeSlot && (activeSlot.slotHour === slot.slotHour);

                acc.push(
                    <ReservationAccordionSlot key={slot.slotHour}
                                              isActive={isActive}
                                              planning={planning}
                                              product={product}
                                              setActive={(slot) => this._setActiveButton(slot)} slot={slot}
                                              slotDay={slotDay}
                    />
                );

                return acc;
            }, []);
    }

    render() {
        const {availableSlots} = this.props;

        return (
            <div className={'reservation-slots'}>
                {this._getAccordionItems(availableSlots)}
            </div>
        );
    }
}

ReservationAccordionSlotsScreen.propTypes = {
    availableSlots  : PropTypes.object.isRequired,
    planning        : PropTypes.object.isRequired,
    product         : PropTypes.object.isRequired,
    slotDay         : PropTypes.string.isRequired,
};

export default ReservationAccordionSlotsScreen;