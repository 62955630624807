import {ClassicActions, ClassicConstantList} from './classic';
import InvalidParameters from '../exceptions/InvalidParameters';
import pluralize from 'pluralize';

const GET_CONST_PREFIX = 'GET_';
const CREATE_CONST_PREFIX = 'CREATE_';
const UPDATE_CONST_PREFIX = 'UPDATE_';
const DELETE_CONST_PREFIX = 'DELETE';

/**
 *
 * @param model
 * @returns {{get: string, create: string, update: string, delete: string}}
 */
function getConstLabel(model) {
    return {
        get: GET_CONST_PREFIX + pluralize(model.toUpperCase()),
        create: CREATE_CONST_PREFIX + model.toUpperCase(),
        update: UPDATE_CONST_PREFIX + model.toUpperCase(),
        delete: DELETE_CONST_PREFIX + model.toUpperCase(),
    };
}

/**
 * @param model
 * @param constants
 * @constructor
 */
export const ModelActionsConst = (model, constants = ['get', 'create', 'update', 'delete']) => {
    const constLabel = getConstLabel(model);

    const modelActions = {};

    constants.forEach((constant) => {
        modelActions[constant] = ClassicConstantList(constLabel[constant]);
    });

    return modelActions;
};

/**
 * @param actionConsts
 * @param api
 * @param methods
 * @returns {{get: ClassicActions, create: ClassicActions, update: ClassicActions, delete: ClassicActions}}
 * @constructor
 */
export const ModelActions = (actionConsts, api, methods = ['get', 'create', 'update', 'delete']) => {
    const actions = {};

    if (methods.indexOf('get') > -1) {
        actions.get = new ClassicActions(actionConsts.get, {
            action: (parameters) => {
                return api.get(parameters);
            },
        });
    }

    if (methods.indexOf('create') > -1) {
        actions.create = new ClassicActions(actionConsts.create, {
            action: (parameters) => {
                return api.create(parameters);
            },
        });
    }

    if (methods.indexOf('update') > -1) {
        actions.update = new ClassicActions(actionConsts.update, {
            action: (parameters) => {
                if (! parameters.id) {
                    throw new InvalidParameters(['id']);
                }

                return api.update(parameters.id, parameters);
            },
        });
    }

    if (methods.indexOf('delete') > -1) {
        actions.delete = new ClassicActions(actionConsts.delete, {
            action: (parameters) => {
                if (! parameters.id) {
                    throw new InvalidParameters(['id']);
                }


                return api.delete(parameters.id, parameters);
            },
        });
    }

    return actions;
};