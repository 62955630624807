import {combineReducers} from 'redux';

import AuthReducer from './AuthReducer';
import ConfigReducer from './ConfigReducer';
import CourseReducer from './CourseReducer';
import CustomerReducer from './CustomerReducer';
import PanelReducer from './PanelReducer';
import PlanningReducer from './PlanningReducer';
import ProductReducer from './ProductReducer';
import ReservationReducer from './ReservationReducer';
import UserReducer from './UserReducer';

export default combineReducers({
    Auth        : AuthReducer,
    Config      : ConfigReducer,
    Course      : CourseReducer,
    Customer    : CustomerReducer,
    Panel       : PanelReducer,
    Reservation : ReservationReducer,
    Planning    : PlanningReducer,
    Product     : ProductReducer,
    User        : UserReducer,
});
