import Manager from './Manager';
import { actions } from '../../actions/ProductAction';

class ProductManager extends Manager {
    constructor(actions) {
        super(actions);

        this.searchFields = {
            ...this.searchFields,
        };
    }
}

export default new ProductManager(actions);

