import React, {Component} from 'react';
import PanelComponent from './PanelComponent';
import {ACTIONS} from '../../utils/actions';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import PropTypes from 'prop-types';

import ErrorBoundaryComponent from '../ErrorBoundaryComponent';
import PanelTODOScreen from '../../screens/TODO/TODO';

class PanelListComponent extends Component {
    shouldComponentUpdate(nextProps) {
        return (nextProps.aPanel.length) !== (this.props.aPanel.length);
    }

    render() {
        const {aPanel, left} = this.props;
        const position = (typeof left !== 'undefined') ? 'left' : 'right';
        const defaultZIndex = 1029;

        return (
            <TransitionGroup component={null}>
                {
                    aPanel.map((panel) => (
                        <CSSTransition
                            key={`trans_${position}_${panel.id}`}
                            classNames={'panel'}
                            timeout={{enter: 500, exit: 500}}>

                            <div className={'panel-container panel-' + position}
                                 style={{
                                     width: panel.parameters.width ?? PanelListComponent._getWidth(),
                                     zIndex: defaultZIndex+panel.id
                                 }}>
                                {PanelListComponent._renderPanel(panel, position)}
                            </div>

                        </CSSTransition>
                    ))
                }
            </TransitionGroup>
        );
    }

    static _getWidth() {
        return '49%';
    }

    static _renderPanel(panel, position) {
        if (typeof panel === 'undefined' || typeof panel.label === 'undefined' || typeof panel.id === 'undefined') {
            return (<div/>);
        }

        panel.position = position;

        if (typeof panel.parameters === 'undefined') {
            panel.parameters = {};
        }

        // Add _id on parameters to allow closePanel based on ID
        panel.parameters._id = panel.id;
        panel.parameters.key = panel.id;

        let component = (<div/>);

        switch (panel.label) {
            // NOT DEFINED
            case ACTIONS.NOT_DEFINED:
                component = (<PanelTODOScreen {...panel.parameters} />);
                panel.title = 'TODO';
                break;

            default:
                console.error('Invalid panel action', panel.label);
                break;
        }

        return (
            <PanelComponent {...panel}>
                <ErrorBoundaryComponent>{component}</ErrorBoundaryComponent>
            </PanelComponent>
        );
    }
}

PanelListComponent.propTypes = {
    aPanel: PropTypes.array,
    left: PropTypes.bool
};

export default PanelListComponent;