import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardBody,
    CardHeader,
    Input,
} from 'reactstrap';

import FriendLine from './FriendLineScreen';

import {getSpanError} from "../functions";
import LaddaButton, {SLIDE_LEFT} from 'react-ladda';
import ValidatorService from "../../services/Validator/ValidatorService";

const ERROR_ALREADY_FRIENDS         = 'est déjà votre ami';

const API_ERROR_FRIEND_NO_ONLINEABLE    = 'Friend not onlineable';
const API_ERROR_NO_CUSTOMER_EMAIL       = 'No customer found with this email';
const UI_ERROR_INVALID_EMAIL_FORAMT     = 'Invalid email format';

class FriendScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            alreadyInFriends    : null,
            customer            : null,
            friendToAdd         : '',
            friends             : [],
        };

        this._addFriend             = this._addFriend.bind(this);
        this._checkEmail            = this._checkEmail.bind(this);
        this._deleteFriend          = this._deleteFriend.bind(this);
        this._setFriendToAdd        = this._setFriendToAdd.bind(this);

        // In case of screen refresh, currentUser is no more known, reload him
        if (props.currentUser) {
            props.searchCustomer(props.currentUser.id);
        } else {
            props.loadCurrentCustomer();
        }
    }

    /**
     * Add a new friend to the customer
     * @param friend
     * @private
     */
    _addFriend(friendEmail) {
        const {friends} = this.state;

        if (!this._validateEmail(friendEmail)) {
            return;
        }

        let friend = friends.find(({email}) => email === friendEmail);
        if (friend) {
            this.setState({alreadyInFriends: friend});
            return;
        }

        // Functions
        const {addFriend} = this.props;

        addFriend({email: friendEmail}, (data, success) => {
            if (success) {
                this.setState({alreadyInFriends: false, friends: data.friends, friendToAdd: ''});
            }
        });
    }

    /**
     * Verify that email respect valid email format
     * @param friendEmail
     * @private
     */
    _checkEmail(friendEmail) {
        if (this._validateEmail(friendEmail)) {
           this._setFriendToAdd(friendEmail)
        } else {
            this.setState({
                alreadyInFriends: null,
                customerError: UI_ERROR_INVALID_EMAIL_FORAMT,
            });
        }
    }

    /**
     * Delete a friend from a customer
     * @param friendId
     * @private
     */
    _deleteFriend(friendId) {
        // Functions
        const {deleteFriend} = this.props;

        deleteFriend(friendId, (data, success) => {
            if (success) {
                this.setState({friends: data.friends});
            }
        });
    }

    /**
     * Set object that is the friend to add
     * @param friend
     * @private
     */
    _setFriendToAdd(friendEmail) {
        this.setState({
            alreadyInFriends: null,
            friendToAdd: friendEmail,
            customerError: null,
        });
    }

    _validateEmail(email) {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (!prevProps.currentUser && this.props.currentUser) {
            this.props.searchCustomer(this.props.currentUser.id);
        }

        if (!prevProps.customer && this.props.customer) {
            this.setState({customer: this.props.customer, friends: this.props.customer.friends});
        }

        if (!prevProps.customerError && this.props.customerError) {
            this.setState({customerError: this.props.customerError});
        }
    }

    render() {
        const {alreadyInFriends, customerError, friends, friendToAdd} = this.state;

        let errorString = null;
        switch (customerError) {
            case API_ERROR_NO_CUSTOMER_EMAIL:
                errorString = 'Aucun ami trouvé avec cet email';
                break;
            case API_ERROR_FRIEND_NO_ONLINEABLE:
                errorString = 'Contactez votre Golf pour activer la réservation en ligne de votre ami';
                break;
            case UI_ERROR_INVALID_EMAIL_FORAMT:
                errorString = 'Format d\'email invalide';
                break;
            default :
                errorString = null;
        }

        const activeFriendsList = friends.reduce((acc, friend) => {
            if (friend.active === 1) {
                acc.push(friend);
            }
            return acc;
        }, []);

        return (
            <Card>
                <CardHeader>
                    <h2>{'Mes amis'}</h2>
                </CardHeader>
                <CardBody>
                    <div className={'onlineable'}>
                        <Fragment>
                            <div className={' mt-3 d-flex'} style={{alignItems: 'baseline'}}>
                                <label className={'label-info left-label mr-2'}>{'Ami recherché'}</label>
                                <div style={{display: 'inline', width: 300, marginRight: 20, paddingBottom: 20}}>
                                    <Input id={'friend_email'}
                                           name={'friend_email'}
                                           value={friendToAdd}
                                           onChange={(event) => this._setFriendToAdd(event.target.value)}
                                           onBlur={(event) => this._checkEmail(event.target.value)}
                                           placeholder={'Email'}
                                    />
                                    { errorString && getSpanError(errorString) }
                                    { alreadyInFriends && getSpanError(`${alreadyInFriends.full_name} ${ERROR_ALREADY_FRIENDS}`)}
                                </div>
                                <LaddaButton
                                    className={'btn btn-primary btn-ladda'}
                                    data-style={SLIDE_LEFT}
                                    onClick={() => {
                                        if (friendToAdd.length > 0) {
                                            this._addFriend(friendToAdd);
                                        }
                                    }}
                                >
                                    {'Ajouter'}
                                </LaddaButton>
                            </div>
                            {
                                activeFriendsList.length > 0 &&
                                <div className={'mt-3'}>
                                    <label className={'label-info left-label'}>{'Liste des amis'}</label>
                                    <FriendLine
                                        deleteFriend={(friendId) => this._deleteFriend(friendId)}
                                        friends={friends}
                                    />
                                </div>
                            }
                        </Fragment>
                    </div>
                </CardBody>
            </Card>
        );
    }
}

FriendScreen.propTypes = {
    addFriend           : PropTypes.func.isRequired,
    customer            : PropTypes.object,
    deleteFriend        : PropTypes.func.isRequired,
    loadCurrentCustomer : PropTypes.func.isRequired,
    searchCustomer      : PropTypes.func.isRequired,
};

export default FriendScreen;