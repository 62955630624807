import React, {Component} from 'react';
import {Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, Row} from 'reactstrap';
import PropTypes from 'prop-types';

class AuthScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            forgottenPasswordMessageDisplay: false,
            username: '',
            password: '',
        };

        this._handleChange = this._handleChange.bind(this);
        this._handleSubmit = this._handleSubmit.bind(this);
    }

    _handleChange(attribute, value) {
        let change = {};
        change[attribute] = value;

        this.setState(change);
    }

    _handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        this.props.login(this.state.username, this.state.password);
    }

    render() {
        const {forgottenPasswordMessageDisplay, username, password} = this.state;

        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="8">
                            <CardGroup>
                                <Card className="p-4">
                                    <div className={'login'}>
                                        <div className={'logo'}/>
                                    </div>

                                    <CardBody>
                                        <h2 className="text-muted">{'Connexion à votre compte'}</h2>

                                        <form onSubmit={this._handleSubmit}>
                                            <InputGroup className="mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="icon-user"/>
                                                    </span>
                                                </div>

                                                <Input type="text" placeholder="Email" required autoFocus
                                                       value={this.state.username}
                                                       onChange={(event) => this._handleChange('username', event.target.value)}
                                                />
                                            </InputGroup>

                                            <InputGroup className="mb-4">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="icon-lock"/>
                                                    </span>
                                                </div>

                                                <Input type="password" placeholder="Mot de passe" required
                                                       value={this.state.password}
                                                       onChange={(event) => this._handleChange('password', event.target.value)}
                                                />
                                            </InputGroup>
                                            <div className={'d-flex justify-content-end'}>
                                                <label className={'text-muted'} onClick={() => this.setState({forgottenPasswordMessageDisplay: true})}>{'Mot de passe perdu ?'}</label>
                                            </div>
                                            <div className={'d-flex justify-content-end'}>
                                                {forgottenPasswordMessageDisplay && <div className="error">{'Veuillez contacter votre golf pour obtenir un nouveau mot de passe'}</div>}
                                            </div>
                                            <Button
                                                type={'submit'}
                                                disabled={username.length === 0 || password.length === 0}
                                                color="primary" className="px-4"
                                            >{'Se connecter'}</Button>
                                        </form>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

AuthScreen.propTypes = {
    login: PropTypes.func.isRequired,
    error: PropTypes.string,
};

export default AuthScreen;
