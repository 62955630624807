import PanelManager from '../../services/Panel/PanelService';
import {
    closeAllLeftPanel,
    closeAllRightPanel,
    closePanel,
    openLeftPanel,
    openRightPanel,
} from '../../actions/PanelAction';

export default function (dispatch, ownProps) {
    let originId = undefined;

    if (typeof ownProps !== 'undefined' && typeof ownProps._id !== 'undefined') {
        originId = ownProps._id;
    }

    return {
        closePanel: (id, status) => {
            if (typeof status === 'undefined') {
                status = true;
            }

            PanelManager.closePanel(id, status);
            dispatch(closePanel(id, status));
        },

        openRightPanel: (panel, parameters, callbacks) => {
            dispatch(openRightPanel(panel, parameters, callbacks, originId));
        },

        closeAllRightPanel: () => {
            PanelManager.closeAllRightPanel();
            dispatch(closeAllRightPanel());
        },

        openLeftPanel: (panel, parameters, callbacks) => {
            dispatch(openLeftPanel(panel, parameters, callbacks, originId));
        },

        closeAllLeftPanel: () => {
            PanelManager.closeAllLeftPanel();
            dispatch(closeAllLeftPanel());
        },

        closeAllPanel: () => {
            dispatch(closeAllLeftPanel());
            dispatch(closeAllRightPanel());
        }
    };
}