import { connect } from 'react-redux';
import PanelManagerComponent from '../../components/Panel/PanelManagerComponent';

const mapStateToProps = (state) => {
    return {
        aLeftPanel: state.Panel.left,
        aRightPanel: state.Panel.right,
    };
};

export default connect(mapStateToProps)(PanelManagerComponent);