import React, {Component} from 'react';
import {withRouter}     from 'react-router-dom';
import {NavbarBrand}    from 'reactstrap';
import PropTypes        from 'prop-types';

import HeaderDropDownContainer from '../../containers/Header/HeaderDropDownContainer';
import nav                      from '../Sidebar/_nav';

class HeaderComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: this.props.location.pathname,
        };
    }

    display(mode){
        this.setState({
            open : mode
        });
    }

    navMenuMobile(index){
        this.setState({
            active : index,
            open : false
        });
    }

    renderNavItemMobile(item, index){
        return (
            <li key={'nav_' + index}>
                <a href={`#${item.url}`} className={this.state.active.split('/')[1] === item.url.split('/')[1] ? 'active' : ''} onClick={() =>  this.navMenuMobile(item.url)}>{item.name}</a>
            </li>
        );
    }

    render() {
        return (
            <header className="app-header navbar">
                <NavbarBrand id="brand-logo"/>

                    <HeaderDropDownContainer accnt={true}/>
                <div className="nav-mobile">
                    <i className="fa fa-bars" aria-hidden="true" onClick={() =>  this.display(true)}/>
                    <div className={'container-menu' + (this.state.open ? ' active' : '')}>
                        <i className="fa fa-times" aria-hidden="true" onClick={() =>  this.display(false)}/>
                        <ul className="menu-mobile-right">
                            {
                                nav.items.map((item, index) => {
                                    { return this.renderNavItemMobile(item, index);}
                                })
                            }
                        </ul>
                    </div>
                </div>
            </header>
        );
    }
}

HeaderComponent.propTypes = {
    location: PropTypes.object.isRequired,
    user: PropTypes.object,
};

export default withRouter(HeaderComponent);