import store from '../../store';

const dispatch = store.dispatch;

class DispatcherService {
    classicDispatch(actionInit, action, actionSuccess, actionFailure, callback) {
        dispatch(actionInit());

        dispatch(action())
            .then((response) => {
                dispatch(actionSuccess(response.payload.data));

                if (typeof callback === 'function') {
                    callback(response.payload.data, true);
                }
            }).catch((error) => {
                dispatch(actionFailure({message: error.message, reason: error?.response?.data?.error ?? ''}));

                if (typeof callback === 'function') {
                    callback(error.message, false);
                }
            });
    }
}

export default new DispatcherService();