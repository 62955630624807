class InvalidParameters extends Error {
    /**
     * @param {Array} parameters - An array of invalid parameters
     */
    constructor(parameters) {
        super();

        this.message = `Invalid parameters : ${parameters.join(', ')}`;
    }
}

export default InvalidParameters;