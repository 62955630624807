import ReducerFunctions from './functions';

export default class ModelReducer {
    constructor(initialState, consts, entity, reloadList = ['list']) {
        this.consts = consts;
        this.entity = entity;
        this.reloadList = reloadList;
        this.initialState = initialState;

        this.reduce = this.reduce.bind(this);
    }

    reduce(state = this.initialState, action = {}) {

        if (typeof this.consts.get !== 'undefined') {
            switch (action.type) {
                //region index
                case this.consts.get.INIT: {
                    return ReducerFunctions.getInit(state, 'list');
                }

                case this.consts.get.ACTION: {
                    return ReducerFunctions.getProcessed(state, 'list', action);
                }

                case this.consts.get.SUCCESS: {
                    return ReducerFunctions.getSuccess(state, action.payload, 'list', this.entity, action);
                }

                case this.consts.get.FAILURE: {
                    return ReducerFunctions.getFailure(state, action.payload, 'list', action);
                }
            }
        }

        if (typeof this.consts.create !== 'undefined') {
            switch (action.type) {
                //region store
                case this.consts.create.INIT: {
                    return ReducerFunctions.createInit(state, 'create', action);
                }

                case this.consts.create.ACTION: {
                    return ReducerFunctions.createProcessed(state, 'create', action);
                }

                case this.consts.create.SUCCESS: {
                    return ReducerFunctions.createSuccess(state, action.payload, 'create', this.entity);
                }

                case this.consts.create.FAILURE: {
                    return ReducerFunctions.createFailure(state, action.payload, 'create', action);
                }
            }
        }

        if (typeof this.consts.update !== 'undefined') {
            switch (action.type) {

                //region update
                case this.consts.update.INIT: {
                    return ReducerFunctions.updateInit(state, 'detail', action);
                }

                case this.consts.update.ACTION: {
                    return ReducerFunctions.updateProcessed(state, 'detail', action);
                }

                case this.consts.update.SUCCESS: {
                    return ReducerFunctions.updateSuccess(state, action.payload, 'detail', this.reloadList, this.entity, action);
                }

                case this.consts.update.FAILURE: {
                    return ReducerFunctions.updateFailure(state, action.payload, 'detail', action);
                }
            }
        }

        if (typeof this.consts.delete !== 'undefined') {
            switch (action.type) {
                //region update
                case this.consts.delete.INIT: {
                    return ReducerFunctions.deleteInit(state, 'detail');
                }

                case this.consts.delete.ACTION: {
                    return ReducerFunctions.deleteAction(state);
                }

                case this.consts.delete.SUCCESS: {
                    return ReducerFunctions.deleteSuccess(state, action.payload, 'detail', this.reloadList);
                }

                case this.consts.delete.FAILURE: {
                    return ReducerFunctions.deleteFailure(state, action.payload, 'detail');
                }
            }
        }

        return state;
    }
}