import Manager from './Manager';
import { actions } from '../../actions/CustomerAction';

class CustomerManager extends Manager {
    constructor(actions) {
        super(actions);

        this.searchFields = {
            ...this.searchFields,
            login: 'string',
            full_name: 'string',
        };
    }

    addFriend(parameters, callback) {
        return this.dispatch(
            this.actions.addFriend.INIT,
            () => this.actions.addFriend.ACTION(parameters),
            (content) => this.actions.addFriend.SUCCESS(content, {}),
            (error) => this.actions.addFriend.FAILURE(error, {}),
            callback,
        );
    }

    deleteFriend(friendId, callback) {
        return this.dispatch(
            this.actions.deleteFriend.INIT,
            () => this.actions.deleteFriend.ACTION(friendId),
            (content) => this.actions.deleteFriend.SUCCESS(content, {}),
            (error) => this.actions.deleteFriend.FAILURE(error, {}),
            callback,
        );
    }

    getFriends(parameters, callback) {
        return this.dispatch(
            this.actions.getFriends.INIT,
            () => this.actions.getFriends.ACTION(parameters),
            (content) => this.actions.getFriends.SUCCESS(content, {}),
            (error) => this.actions.getFriends.FAILURE(error, {}),
            callback,
        );
    }

    getFriendsReservations(parameters, callback) {
        return this.dispatch(
            this.actions.getFriendsReservations.INIT,
            () => this.actions.getFriendsReservations.ACTION(parameters),
            (content) => this.actions.getFriendsReservations.SUCCESS(content, {}),
            (error) => this.actions.getFriendsReservations.FAILURE(error, {}),
            callback,
        );
    }

    updatePassword(parameters, callback) {
        return this.dispatch(
            this.actions.updatePassword.INIT,
            () => this.actions.updatePassword.ACTION(parameters),
            (content) => this.actions.updatePassword.SUCCESS(content, {}),
            (error) => this.actions.updatePassword.FAILURE(error, {}),
            callback,
        );
    }
}

export default new CustomerManager(actions);

