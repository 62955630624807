import AuthApi from '../api/AuthApi';
import {ClassicActions, ClassicConstantList} from './classic';

export const RESET_USER         = ClassicConstantList('RESET_USER');
export const LOGIN_USER         = ClassicConstantList('LOGIN_USER');
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export let loginUser = new ClassicActions(LOGIN_USER, {
    action: (username, password) => {
        return AuthApi.loginApi({username: username, password: password});
    },
    success: (content) => {
        return {
            type: LOGIN_USER_SUCCESS,
            payload: content
        };
    },
    error: (error) => {
        return {
            type: LOGIN_USER_FAILURE,
            payload: error
        };
    },
});

export let logoutUser = new ClassicActions(RESET_USER, {
    action: () => {
        return AuthApi.logoutApi();
    }
});