import ConfigApi from '../api/ConfigApi';
import {ModelActions, ModelActionsConst} from './ModelAction';

export const ACTION_CONST = {
    ...ModelActionsConst('config'),
};

export const actions = {
    ...ModelActions(ACTION_CONST, ConfigApi),
};
