import ModelApi from './ModelApi';
import {HTTP_GET, HTTP_PUT, HTTP_POST} from '../utils/api';

class CustomerApi extends ModelApi {
    addFriend(parameters){
        return this.callApi(HTTP_POST, `${this.prefix}/friends/add`, parameters);
    }

    deleteFriend(friendId){
        return this.callApi(HTTP_POST, `${this.prefix}/friends/${friendId}/delete`, {});
    }

    getFriends(parameters){
        return this.callApi(HTTP_GET, `${this.prefix}/friends`, parameters);
    }

    getFriendsReservations(parameters){
        return this.callApi(HTTP_GET, `${this.prefix}/friends/reservations`, parameters);
    }

    updatePassword(parameters){
        return this.callApi(HTTP_PUT, `${this.prefix}/password`, parameters);
    }
}

export default new CustomerApi('customer');
