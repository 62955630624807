import React     from 'react';
import PropTypes from 'prop-types';
import Datetime  from 'react-datetime';
import moment    from 'moment';
import Select    from 'react-select';

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    FormGroup,
    Label,
} from 'reactstrap';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

import ReservationAccordionScreen from './ReservationAccordionScreen';
import {
    CONFIG,
    COURSE_SIZE,
    DATE_DISPLAY_FORMAT, MAX_SLOTS,
} from '../../utils/consts';

const FIELD_DATE = 'date_start';
const FIELD_PRODUCT = 'product';
const FIELD_PLANNING = 'planning';

class ReservationScreen extends React.Component {
    now = moment().startOf('d');

    constructor(props) {
        super(props);

        this.state = {
            config       : null,
            dateStart   : moment(),
            dateStop     : null,
            displayResult: false,
            plannings    : [],
            product      : null,
            products     : [],
            reservations : [],
            planning     : null,
        };

        this._handleChange = this._handleChange.bind(this);
        this._isValidDateStart = this._isValidDateStart.bind(this);
        this._onDayChange = this._onDayChange.bind(this);
        this._validDate = this._validDate.bind(this);

        this.props.load();
    }

    /**
     *
     * @param courses
     * @param planningId
     * @returns {{label: string, size: number}}
     */
    static _extractCourseFromPlanningId(courses, planningId) {
        let label = 'non défini';
        let size = COURSE_SIZE.EIGHTEEN;
        let full_duration = 0;
        let half_duration = 0;
        let id = 0;

        if (courses) {
            const filteredCourse = courses.filter((course) => {
                if (typeof course.planning_id === 'object') {
                    return course.planning_id.indexOf(planningId) >= 0;
                }

                return course.planning_id === planningId;
            });

            if (filteredCourse.length === 1) {
                label = 'Parcours ' + filteredCourse[0].label;
                size = filteredCourse[0].size;
                full_duration = filteredCourse[0].full_duration;
                half_duration = filteredCourse[0].half_duration;
                id = filteredCourse[0].id;
            }
        }

        return {label, size, full_duration, half_duration, id};
    }

    _handleChange(attribute, value) {
        let change = {...this.state};

        change[attribute] = value;
        change.displayResult = false;

        this.setState(change);
    }

    static _getFormattedPlanning(courses, planning) {
        const currentItemDatum = ReservationScreen._extractCourseFromPlanningId(courses, planning.id);

        let toReturn = {
            course_id: currentItemDatum.id,
            id       : planning.id,
            label    : currentItemDatum.label
        };

        if (currentItemDatum.size === COURSE_SIZE.EIGHTEEN) {
            toReturn.label += ' : Trou ' + planning.hole_start;
        }

        return toReturn;
    }

    _isValidDateStart(date) {
        if (moment(date).isValid()) {
            const {config, dateStop} = this.state;

            let isValidStartDate = false;
            let isValidEndDate = false;

            let localStartDate = moment(date);

            if (config) {

                if (config.displayToday) {
                    isValidStartDate = localStartDate.startOf('day') >= this.now;
                    // return moment(date).startOf('day') > moment().startOf('day');
                } else {
                    isValidStartDate = localStartDate.startOf('day') > this.now;
                    // return moment(date).startOf('day') >= moment().startOf('day');
                }
                isValidEndDate = ( ! dateStop) || (localStartDate < dateStop.endOf('day'));

                return isValidStartDate && isValidEndDate;
            }
        }

        return true;
    }

    _onDayChange(date) {
        this.setState({dateStart: date, displayResult: false});
    }

    _validDate() {
        const {dateStart, planning, product, dateStop} = this.state;

        if (planning && product) {
            const maxDate = dateStop ? dateStop : moment(dateStart).clone().add(7, 'd');

            this.setState({displayResult: true}, this.props.loadReservation(dateStart, maxDate, planning.id));
        }
    }

    componentDidUpdate() {

        const {
                  configData,
                  courses,
                  plannings,
                  products,
              } = this.props;


        let changes = {};

        if (this.state.products.length === 0 && products.length !== 0) {
            changes.products = products;
        }

        if (courses && courses.length !== 0 && ! this.state.courses) {
            changes.courses = courses;
        }

        if (this.state.courses && this.state.plannings.length === 0 && plannings.length) {
            changes.plannings = plannings
                .filter(function (planning) {
                    return planning.plannable_type === 'course';
                })
                .map((planning) => {
                    return ReservationScreen._getFormattedPlanning(courses, planning);
                }, this);
        }

        if (this.state.courses && this.state.courses.length > 0 && plannings.length === 1 && ! this.state.planning) {
            changes.planning = changes.plannings[0];
        }

        if ( ! this.state.config && configData.length !== 0) {
            changes.config = {
                displayToday : false,
                maxSlots     : MAX_SLOTS,
                displayedDays: 'all',
                onlineMultiCustomer: '0',
            };

            changes.config.displayToday = configData.find(({label}) => label === CONFIG.ONLINE_DISPLAY_TODAY).value === '1';
            changes.config.maxSlots = parseInt(configData.find(({label}) => label === CONFIG.ONLINE_MAX_SLOTS).value);
            changes.config.displayedDays = configData.find(({label}) => label === CONFIG.ONLINE_DISPLAYED_DAYS).value;
            changes.config.onlineMultiCustomer = configData.find(({label}) => label === CONFIG.ONLINE_MULTI_CUSTOMERS).value;

            if ( ! changes.config.displayToday) {
                changes.dateStart = this.state.dateStart.add(1, 'd');
            }

            if (changes.config.displayedDays !== 'all') {
                changes.dateStop = moment().add(parseInt(changes.config.displayedDays), 'd');
            }
        }

        if (Object.keys(changes).length !== 0) {
            this.setState(changes);
        }
    }

    render() {
        const {availableSlots, friendsReservations} = this.props;
        const {
                  config,
                  plannings,
                  dateStart,
                  displayResult,
                  product,
                  products,
                  reservations,
                  planning,
                  dateStop,
              } = this.state;

        return (
            <Card>
                <CardHeader>
                    <h2>{'Réserver'}</h2>
                </CardHeader>
                <CardBody>
                    <div className={'d-flex col-sm-12 col-md-8'}>
                        <FormGroup row={true} className={'col-sm-12 col-md-8'}>
                            <Label htmlFor={FIELD_PLANNING}
                                   className="col-sm-5 col-md-4 col-form-label">{'Parcours'}</Label>
                            <Select
                                name={FIELD_PLANNING}
                                className={'col-sm-5 col-md-6'}
                                value={planning}
                                onChange={(value) => this._handleChange(FIELD_PLANNING, value)}
                                options={plannings}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.label}
                                autoSize={true}
                            />
                        </FormGroup>
                    </div>
                    <div className={'d-flex col-sm-12 col-md-8'}>
                        <FormGroup row={true} className={'col-sm-12 col-md-8'}>
                            <Label htmlFor={FIELD_PRODUCT}
                                   className="col-sm-5 col-md-4 col-form-label">{'Type'}</Label>
                            <Select
                                name={FIELD_PRODUCT}
                                className={'col-sm-5 col-md-6'}
                                value={product}
                                onChange={(value) => this._handleChange(FIELD_PRODUCT, value)}
                                options={products}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.label}
                                autoSize={true}
                            />
                        </FormGroup>
                    </div>
                    <div className={'d-flex col-sm-12 col-md-8'}>
                        <FormGroup row={true} className={'col-sm-12 col-md-8'}>
                            <Label htmlFor={FIELD_DATE}
                                   className="col-sm-5 col-md-4 col-form-label">{'Date de réservation'}</Label>
                            <Datetime
                                key={dateStart + config?.displayToday ?? 0 + config?.displayedDays ?? 0}
                                id={FIELD_DATE}
                                name={FIELD_DATE}
                                className={'planning-header-item col-sm-5 col-md-4'}
                                closeOnSelect={true}
                                dateFormat={DATE_DISPLAY_FORMAT}
                                defaultValue={dateStart}
                                inputProps={{name: 'dateStart', autoComplete: 'off'}}
                                isValidDate={this._isValidDateStart}
                                locale="fr"
                                onChange={this._onDayChange}
                                timeFormat={false}
                            />
                        </FormGroup>
                    </div>
                    { config?.onlineMultiCustomer === '1' &&
                        <div style={{color: 'red', marginBottom:'10px', marginLeft:50}}>
                            {'Un seul créneau pour chaque membre sur la journée est autorisé.'} <br />
                            {'Pour réserver plusieurs créneaux, contactez directement le golf.'}
                        </div>
                    }
                    <div className={'mb-3 d-flex justify-content-center col-sm-3 col-md-5'}>
                        {(dateStop && (dateStop < dateStart)) &&
                        <Label>
                            {'Aucune réservation n\'est possible. Veuillez contacter votre golf.'}
                        </Label>
                        }
                        {( ! dateStop || (dateStop >= dateStart)) &&
                        <Button className={'button ml-1 col-sm-2 col-md-3'}
                                color={'primary'}
                                onClick={() => this._validDate()}
                        >
                            {'Valider'}
                        </Button>
                        }
                    </div>
                    {
                        displayResult &&
                        <ReservationAccordionScreen key={reservations.length}
                                                    availableSlots={availableSlots}
                                                    friendsReservations={friendsReservations}
                                                    planning={planning}
                                                    product={product}
                                                    allowedSlots={config.maxSlots}
                        />
                    }
                </CardBody>
            </Card>
        );
    }
}

ReservationScreen.propTypes = {
    availableSlots     : PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    configData         : PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    courses            : PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    friendsReservations: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    load               : PropTypes.func.isRequired,
    loadReservation    : PropTypes.func.isRequired,
    plannings          : PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    products           : PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
};

export default ReservationScreen;
