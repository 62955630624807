import CustomerEntity from '../models/entity/CustomerEntity';
import {ACTION_CONST} from '../actions/CustomerAction';
import ModelReducer from './ModelReducer';
import ReducerFunctions from "./functions";
import Notifier, {TYPE_ERROR, TYPE_SUCCESS} from '../utils/notifier';

const INITIAL_STATE = {
    data: {},
    view: {
        list: {
            content: [],
            error: null,
            loading: false,
            page: 1,
        },
        create: {
            error: null,
            loading: false,
        },
        detail: {
            content: [],
            error: null,
            loading: false,
        },
        friendsReservations: {
            content: [],
            error: null,
            loading: false,
        }
    }
};

class CustomerReducer extends ModelReducer {
    reduce(state = this.initialState, action = {}) {
        state = super.reduce(state, action);

        switch(action.type) {
            case this.consts.get.INIT: {
                return ReducerFunctions.getInit(state, 'detail');
            }

            case this.consts.get.ACTION: {
                return ReducerFunctions.getProcessed(state, 'detail', action);
            }

            case this.consts.get.SUCCESS: {
                return ReducerFunctions.getSuccess(state, action.payload, 'detail', CustomerEntity);
            }

            case this.consts.get.FAILURE: {
                return ReducerFunctions.getFailure(state, action.payload, 'detail', action);
            }

            case ACTION_CONST.addFriend.INIT: {
                return ReducerFunctions.updateInit(state, 'detail', action);
            }

            case ACTION_CONST.addFriend.ACTION: {
                return ReducerFunctions.updateProcessed(state, 'detail', action);
            }

            case ACTION_CONST.addFriend.SUCCESS: {
                Notifier(TYPE_SUCCESS, 'Ami ajouté, un mail lui a été envoyé');
                return ReducerFunctions.updateSuccess(state, action.payload, 'detail', [], CustomerEntity);
            }

            case ACTION_CONST.addFriend.FAILURE: {
                Notifier(TYPE_ERROR, 'Échec d\'ajout de votre ami');
                return ReducerFunctions.updateFailure(state, action.payload, 'detail', action);
            }

            case ACTION_CONST.getFriendsReservations.INIT: {
                return ReducerFunctions.getInit(state, 'friendsReservations');
            }

            case ACTION_CONST.getFriendsReservations.ACTION: {
                return ReducerFunctions.getProcessed(state, 'friendsReservations', action);
            }

            case ACTION_CONST.getFriendsReservations.SUCCESS: {
                state.data = {...state.data};

                Object.assign(state.data, action.payload);
                state.view['friendsReservations'].content = state.data.data;
                state.view['friendsReservations'].error = null;
                state.view['friendsReservations'].loading = false;

                return {...state};
            }

            case ACTION_CONST.getFriendsReservations.FAILURE: {
                return ReducerFunctions.getFailure(state, action.payload, 'friendsReservations');
            }


            case ACTION_CONST.updatePassword.INIT: {
                return ReducerFunctions.updateInit(state, 'detail', action);
            }

            case ACTION_CONST.updatePassword.ACTION: {
                return ReducerFunctions.updateProcessed(state, 'detail', action);
            }

            case ACTION_CONST.updatePassword.SUCCESS: {
                Notifier(TYPE_SUCCESS, 'Mot de passe modifié');
                return ReducerFunctions.updateSuccess(state, action.payload, 'detail', [], CustomerEntity);
            }

            case ACTION_CONST.updatePassword.FAILURE: {
                Notifier(TYPE_ERROR, 'Mot de passe courant et/ou nouveau mot de passe invalide');
                return ReducerFunctions.updateFailure(state, action.payload, 'detail', action);
            }

            default: {
                return state;
            }
        }
    }
}

export default new CustomerReducer(INITIAL_STATE, ACTION_CONST, CustomerEntity, ['list']).reduce;