import React, {Component} from 'react';

class NotFoundScreen extends Component {
    render() {
        return (
            <div>
                <p>The page you are looking for does not exist.</p>
            </div>
        );
    }
}

export default NotFoundScreen;