import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Dropdown,
} from 'reactstrap';
import PropTypes from 'prop-types';

class HeaderDropDownComponent extends Component {
    constructor(props) {
        super(props);

        this._toggle = this._toggle.bind(this);

        this.state = {
            dropdownOpen: false,
        };
    }

    _toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    dropAccnt() {
        const {logoutUser, myProfil} = this.props;

        return (
            <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this._toggle}>
                <DropdownToggle nav className={'right-menu'}>
                    <i className="fa fa-user-circle" style={{fontSize: 30, color: 'white'}}/>
                </DropdownToggle>

                <DropdownMenu right>
                    <DropdownItem header tag="div" className="text-center">
                        <strong>Account</strong>
                    </DropdownItem>
                    <DropdownItem onClick={() => {myProfil();}}>
                        <i className="fa fa-user" />{'Mon profil'}
                    </DropdownItem>
                    <DropdownItem onClick={() => {logoutUser();}}>
                        <i className="fa fa-power-off" />{'Logout'}
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    }

    render() {
        const {accnt} = this.props;

        return (
            accnt ? this.dropAccnt() : null
        );
    }
}

HeaderDropDownComponent.defaultProps = {
    accnt: false,
};

HeaderDropDownComponent.propTypes = {
    accnt       : PropTypes.bool,
    logoutUser  : PropTypes.func.isRequired,
    myProfil      : PropTypes.func.isRequired,
};

export default withRouter(HeaderDropDownComponent);
