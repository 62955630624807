import ReducerFunctions from './functions';
import Notifier, {TYPE_ERROR, TYPE_SUCCESS} from '../utils/notifier';
import {
    RESET_USER,
    LOGIN_USER,
    LOGIN_USER_FAILURE,
    LOGIN_USER_SUCCESS,
} from '../actions/AuthAction';

const INITIAL_STATE = {
    data: {},
    view: {
        user: {
            content: null,
            error: null,
            loading: true,
        }
    }
};

const AuthReducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {

        case LOGIN_USER: {
            return ReducerFunctions.createInit(state, 'user', action);
        }

        case LOGIN_USER_SUCCESS: {
            Notifier(TYPE_SUCCESS, 'Connexion réussie, bienvenue !');
            return ReducerFunctions.oneSuccess(state, action.payload, 'user', (data) => data, action);
        }

        case LOGIN_USER_FAILURE: {
            Notifier(TYPE_ERROR, 'Utilisateur et/ou mot de passe incorrect(s)');
            return ReducerFunctions.defaultFailure(state, action.payload, 'user');
        }

        case RESET_USER: {
            state.view.user = {
                content: null,
                error: null,
                loading: true,
            };
            return {...state};
        }

        default: {
            return state;
        }
    }
};

export default AuthReducer;