import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import HeaderDropDownComponent from '../../components/Header/HeaderDropDownComponent';
import AuthService from '../../services/Auth/AuthService';
import {admin, auth} from '../../routing/route';

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        logoutUser:() => {
            let callback = () => {
                ownProps.history.push(auth.login.path);
            };
            AuthService.logoutUser(callback);
        },
        myProfil: () => {
            ownProps.history.push(admin.customer.path);
        }
    };
};

export default withRouter(connect(null, mapDispatchToProps)(HeaderDropDownComponent));