import React, {Component} from 'react';
import PropTypes    from 'prop-types';
import {
    Table,
} from 'reactstrap';

class FriendLine extends Component {
    render() {
        const {friends} = this.props;

        // Functions
        const {deleteFriend} = this.props;

        return (
            <Table className={'table-responsive table-borderless col-sm-10 col-md-8'}>
                <thead>
                <tr>
                    <th>{'Nom'}</th>
                    <th>{'Prénom'}</th>
                    <th className={'text-center'}>{'Handicap'}</th>
                    <th className={'text-center'}>{'Supprimer'}</th>
                </tr>
                </thead>
                <tbody>
                    {
                        friends.map((friend) => {
                            return (
                                <tr key={friend.id}>
                                    <td>{friend.last_name}</td>
                                    <td>{friend.first_name}</td>
                                    <td className={'text-center'}>{friend.handicap}</td>
                                    <td className={'text-center'}
                                        onClick={() => deleteFriend(friend.id)}
                                    >
                                        <i className={'fa fa-trash delete'} />
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        );
    }
}

FriendLine.propTypes = {
    deleteFriend: PropTypes.func.isRequired,
    friends     : PropTypes.array.isRequired,
};

export default FriendLine;