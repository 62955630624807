import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import auth from '../../utils/auth';
import {auth as authRoute} from '../../routing/route';

const PrivateRouteComponent = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            auth.getAccessToken() !== null ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: authRoute.login.path,
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);

export default PrivateRouteComponent;