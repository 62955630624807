import UserApi from '../api/UserApi';
import {ModelActions, ModelActionsConst} from './ModelAction';
import {ClassicActions, ClassicConstantList} from './classic';

export const ACTION_CONST = {
    ...ModelActionsConst('user'),

    getMe: ClassicConstantList('GET_ME')
};

export const actions = {
    ...ModelActions(ACTION_CONST, UserApi),

    getMe: new ClassicActions(ACTION_CONST.getMe, {
        action: () => {
            return UserApi.getMe();
        }
    })
};