export const CLOSE_PANEL = 'CLOSE_PANEL';
export const OPEN_RIGHT_PANEL = 'OPEN_RIGHT_PANEL';
export const CLOSE_ALL_RIGHT_PANEL = 'CLOSE_ALL_RIGHT_PANEL';
export const OPEN_LEFT_PANEL = 'OPEN_LEFT_PANEL';
export const CLOSE_ALL_LEFT_PANEL = 'CLOSE_ALL_LEFT_PANEL';

export function closePanel(id, status) {
    return {
        type: CLOSE_PANEL,
        payload: id,
        status: status,
    };
}

export function openRightPanel(panel, parameters = {}, callbacks = {}, originId) {
    return {
        type: OPEN_RIGHT_PANEL,
        payload: {
            label: panel,
            parameters: parameters,
            callbacks: callbacks,
            originId: originId
        }
    };
}

export function closeAllRightPanel(status) {
    return {
        type: CLOSE_ALL_RIGHT_PANEL,
        status: status,
    };
}

export function openLeftPanel(panel, parameters = {}, callbacks = {}, originId) {
    return {
        type: OPEN_LEFT_PANEL,
        payload: {
            label: panel,
            parameters: parameters,
            callbacks: callbacks,
            originId: originId
        }
    };
}

export function closeAllLeftPanel(status) {
    return {
        type: CLOSE_ALL_LEFT_PANEL,
        status: status,
    };
}