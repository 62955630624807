import Entity from './Entity';
import {MODELS} from '../../utils/consts';
import CustomerEntity from "./CustomerEntity";

export default class UserEntity extends Entity {
    modelKey() {
        return MODELS.USER;
    }

    appends () {
        return [
            'full_name',
        ];
    }

    relationships () {
        return {
            friends: CustomerEntity
        };
    }

    getFullNameAttribute() {
        let full_name = [];

        if (this.last_name) {
            full_name.push(this.last_name);
        }
        if (this.first_name) {
            full_name.push(this.first_name);
        }

        return full_name.join(' ');
    }
}