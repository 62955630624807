import Axios from 'axios';
import qs from 'qs';
import {ROOT_URL, AXIOS_CONF, HTTP_GET, HTTP_POST, HTTP_PUT, HTTP_DELETE} from '../utils/api';
import auth from '../utils/auth';

let frontUrl    = ROOT_URL + 'online/';

export default class Api {
    /**
     *
     * @param file
     * @returns {boolean}
     */
    isFile(file) {
        return (
            typeof file === 'object'
            && file !== null
            && file.constructor === File
        );
    }

    /**
     *
     * @param currentKey
     * @param item
     * @param formData
     * @returns {*}
     */
    addFormDataParameters(currentKey, item, formData) {
        if (Array.isArray(item)) {
            for (let keyParam in item) {
                formData = this.addFormDataParameters(currentKey + '[]', item[keyParam], formData);
            }
        } else if (typeof item === 'object' && item !== null && item.constructor == Object) {
            let keys = Object.keys(item);

            for (let i = 0; i < keys.length; ++i) {
                const keyParam = keys[i];

                formData = this.addFormDataParameters(currentKey + '[' + keyParam + ']', item[keyParam], formData);
            }
        } else {
            formData.append(currentKey, item);
        }

        return formData;
    }

    /**
     *
     * @param httpMethod
     * @param route
     * @param params
     * @returns {AxiosPromise<any> | AxiosPromise}
     */
    callApi(httpMethod, route, params = {}) {
        // Waiting for result
        let promise;
        let token = auth.getAccessToken();
        let currentConf = {headers: {}};
        currentConf.headers = Object.assign(currentConf.headers, AXIOS_CONF.headers);

        if (token !== null) {
            currentConf.headers['Authorization'] = 'Bearer ' + token;
        }

        let transformParams = false;
        for (let key in params) {
            let param = params[key];
            if (this.isFile(param)) {
                transformParams = true;
                break;
            } else if (Array.isArray(param) && this.isFile(param[0])) {
                transformParams = true;
                break;
            }
        }

        if (transformParams) {
            let tempParams = new FormData();
            for (let key in params) {
                let param = params[key];

                tempParams = this.addFormDataParameters(key, param, tempParams);
            }

            params = tempParams;
        }

        let url = frontUrl + route;

        switch (httpMethod) {
            case HTTP_GET: {
                let urlParam = qs.stringify(params);
                if (urlParam.length) {
                    url += '?' + urlParam;
                }
                promise = Axios.get(url, currentConf);
            }
                break;
            case HTTP_POST:
                promise = Axios.post(url, params, currentConf);
                break;
            case HTTP_PUT:
                promise = Axios.put(url, params, currentConf);
                break;
            case HTTP_DELETE:
                promise = Axios.delete(url, currentConf);
                break;
            default:
                console.error(`API::callApi:: Unknown http method: ${httpMethod}`);
                break;
        }

        return promise;
    }
}