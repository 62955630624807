import React from 'react';
import PropTypes from 'prop-types';

import {
    Button,
    Label,
} from 'reactstrap';

class SlotReservation extends React.Component {
    render() {
        const {customers, planning, product, slot, slotDay} = this.props;

        // Functions
        const {bookSlot} = this.props;

        const params = {...slot, slotDay: slotDay, planning: planning, product: product};
        return (
            <div className={'one-slot-reservation'}>
                {
                    slot.hasFriends && slot.customer_ids.map((customer_id) => {
                        const friend = customers.find(({id}) => id === customer_id);
                        return(
                            <Label className={'slot-label'}>
                                {friend.full_name}
                            </Label>
                        )
                    })
                }
                <Button className={'btn-reservation'}
                        color={'primary'}
                        onClick={() => bookSlot(params)}
                >
                    {'Réserver le départ'}
                </Button>
            </div>
        );
    }
}

SlotReservation.propTypes = {
    customers   : PropTypes.array,
    bookSlot    : PropTypes.func.isRequired,
    planning    : PropTypes.object.isRequired,
    product     : PropTypes.object.isRequired,
    slot        : PropTypes.object.isRequired,
    slotDay     : PropTypes.string.isRequired,
};

export default SlotReservation;