import React, {Fragment}         from 'react';
import moment                    from 'moment';
import PropTypes                 from 'prop-types';
import Select                    from 'react-select';
import {Label,}                  from 'reactstrap';
import LaddaButton, {SLIDE_LEFT} from 'react-ladda';


import Loader     from '../../components/Loader/LoaderComponent';
import {
    DATE_DISPLAY_FORMAT,
    DATE_REQUEST_FORMAT,
    DATETIME_REQUEST_FORMAT,
    TIME_DISPLAY_FORMAT_HOUR_SEPARATOR,
}                 from '../../utils/consts';
import {getPrice} from './functions';

class ReservationStep1 extends React.Component {
    constructor(props) {
        super(props);

        let initMeAndFriendsArray = [];
        initMeAndFriendsArray.push(props.user);

        props.user.friends.forEach((friend) => {
            if (friend.active === 1) {
                initMeAndFriendsArray.push(friend);
            }
        });

        this.state = {
            meAndFriendsArray: initMeAndFriendsArray,
            params           : null,
            otherUsers       : [],
            otherPrices      : [],
            userPrice        : null,
        };

        this._createReservation = this._createReservation.bind(this);
        this._updateUsers = this._updateUsers.bind(this);
    }

    _createReservation() {
        const {user} = this.props;
        const {otherUsers, otherPrices, params, userPrice} = this.state;

        const formattedDateStart = moment(params.slotDay + params.slotHour, DATE_DISPLAY_FORMAT + 'HH:mm').format(DATETIME_REQUEST_FORMAT);

        const commonParams = {
            course_planning_id: params.planning.id,
            planning_id       : params.planning.id,
            product_id        : params.product.id,
            gf_product_id     : params.product.id,
            date_start        : formattedDateStart,
        };

        let reservations = [];
        reservations[0] = [{
            ...commonParams,
            customer: {customer_id: user.id, price_category_id: userPrice.id}
        }];

        Object.keys(otherUsers).forEach((key) => {
            const oneUser = otherUsers[key];

            if (oneUser) {
                const onePrice = otherPrices[key];

                reservations[0].push({
                    ...commonParams,
                    customer: {customer_id: oneUser.id, price_category_id: onePrice.id}
                });
            }
        });

        this.props.createReservation({reservations: reservations}, (data, success) => {
            let parameters = {
                planning_label: params.planning.label,
                product_label : params.product.label,
                slotDay       : params.slotDay,
                slotHour      : params.slotHour,
                success       : success,
            };

            this.props.setReservationConfirmation(parameters);
        });
    }

    _getFormattedPrice(price) {
        return getPrice(price).toFixed(2) + '€';
    }

    _updateUsers(index, value) {
        const {params} = this.state;

        let change = {...this.state};

        change.otherUsers[index] = value;

        if (value) {
            const parameters = {
                course_id : params.planning.course_id,
                date      : moment(params.slotDay, DATE_DISPLAY_FORMAT).format(DATE_REQUEST_FORMAT),
                product_id: params.product.id,
            };

            this.props.getCustomerPrice(value.id, parameters, (data, success) => {
                if (success) {
                    change.otherPrices[index] = data;
                    this.setState(change);
                }
            });
        } else {
            change.otherPrices[index] = value;
        }

        this.setState(change);
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({params: this.props.location.state.params}, () => {
                const params = {
                    course_id : this.state.params.planning.course_id,
                    date      : moment(this.state.params.slotDay, DATE_DISPLAY_FORMAT).format(DATE_REQUEST_FORMAT),
                    product_id: this.state.params.product.id,
                };
                this.props.getCustomerPrice(this.props.user.id, params, (data, success) => {
                    if (success) {
                        this.setState({userPrice: data});
                    }
                });
            });
        }
    }

    _renderCustomerPriceInfo(priceObject, index = null) {
        let priceLabel = null;
        let priceCategoryLabel = null;
        let specificClass = '';

        if (index) {
            priceLabel = priceObject.length > 0 && priceObject[index] ? this._getFormattedPrice(priceObject[index].value) : null;
            priceCategoryLabel = priceObject.length > 0 && priceObject[index] ? priceObject[index].label : null;
        } else {
            priceLabel = this._getFormattedPrice(priceObject.value);
            priceCategoryLabel = priceObject.label;
            specificClass = 'ml-3';
        }

        if (priceLabel && priceCategoryLabel) {
            return (
                <Fragment>
                    <div className={`mt-1 mb-3 price-info ${specificClass}`}>
                        {priceLabel} - {priceCategoryLabel}
                    </div>
                </Fragment>
            );
        } else {
            return null;
        }
    }

    _renderInputs(availableSlots) {
        const {meAndFriendsArray, otherUsers, otherPrices} = this.state;

        let inputArray = [];

        for (let i = availableSlots; i > 1; i--) {

            inputArray.push(
                <div className={'col-sm-2 col-md-3'} key={`other_${i}`}>
                    <Select
                        autoSize={true}
                        getOptionLabel={(option) => option.full_name}
                        getOptionValue={(option) => option.id}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(value) => this._updateUsers(i, value)}
                        options={meAndFriendsArray}
                        className={'friend-select'}
                        value={otherUsers[i]}
                    />
                    {this._renderCustomerPriceInfo(otherPrices, i)}
                </div>
            );
        }

        return inputArray;
    }

    render() {
        const {isPending, user} = this.props;
        const {meAndFriendsArray, params, otherPrices, userPrice} = this.state;

        if ( ! params || ! userPrice) {
            return (
                <Loader />
            );
        }

        const availableSlotsString = params.availableSlots === 1 ? 'slot disponible' : 'slots disponibles';

        let friends = [];
        if (params.hasFriends) {
            friends = params.customer_ids.reduce((acc, customer_id) => {

                const friend = meAndFriendsArray.find(({id}) => id === customer_id);
                acc.push(friend);
                return acc;
            }, []);
        }

        let totalPrice = userPrice.value;
        Object.values(otherPrices).forEach((price) => {
            if (price) {
                totalPrice += price.value;
            }
        });

        return (
            <div className={'reservation-step-one'}>
                <h2 className={'col-sm-12'}>{'Votre réservation étape 1'}</h2>
                <h5 className={'mt-3 col-sm-12'}>{`Départ le ${params.slotDay} à ${moment(params.slotHour, 'HH:mm').format(TIME_DISPLAY_FORMAT_HOUR_SEPARATOR)} (${params.availableSlots} ${availableSlotsString})`}</h5>
                <h5 className={'mt-3 col-sm-12'}>{`Parcours : ${params.planning.label} - ${params.product.label}`}</h5>
                <h5 className={'mt-3 col-sm-12'}>{`Montant total TTC : ${this._getFormattedPrice(totalPrice)}`}</h5>
                <div className={'mt-3 reservation-slots'}>
                    {
                        friends.map((friend) => {
                            return (
                                <div key={`friend_${friend.id}`}>
                                    <Label key={'friend' + friend.id} className={'slot-label'}>
                                        {friend.full_name}
                                    </Label>
                                </div>
                            );
                        })
                    }
                    <div>
                        <Label key={`user_${user.id}`} className={'ml-3 slot-label'}>
                            {user.full_name}
                        </Label>
                        {this._renderCustomerPriceInfo(userPrice)}
                    </div>
                    {this._renderInputs(params.availableSlots)}
                </div>
                <LaddaButton
                    className={'btn btn-primary btn-ladda mt-3 btn-reservation'}
                    loading={isPending}
                    data-style={SLIDE_LEFT}
                    onClick={() => this._createReservation()}
                >
                    {'Créer'}
                </LaddaButton>
            </div>
        );
    }
}

ReservationStep1.propTypes = {
    createReservation         : PropTypes.func.isRequired,
    getCustomerPrice          : PropTypes.func.isRequired,
    isPending                 : PropTypes.bool,
    params                    : PropTypes.object,
    setReservationConfirmation: PropTypes.func.isRequired,
    user                      : PropTypes.object.isRequired,
};

export default ReservationStep1;