import UserEntity from '../models/entity/UserEntity';
import {ACTION_CONST} from '../actions/UserAction';
import ModelReducer from './ModelReducer';
import ReducerFunctions from './functions';

const INITIAL_STATE = {
    data: {},
    view: {
        list: {
            content: [],
            error: null,
            loading: true,
            page: 1,
        },
        current: {
            content: null,
            error: null,
            loading: true,
        },
        create: {
            error: null,
            loading: false,
        },
        detail: {
            error: null,
            loading: false,
        }
    }
};

class UserReducer extends ModelReducer {
    reduce(state = this.initialState, action = {}) {
        state = super.reduce(state, action);

        switch(action.type) {
            case this.consts.getMe.INIT: {
                return ReducerFunctions.oneInit(state, 'current');
            }

            case this.consts.getMe.ACTION: {
                return ReducerFunctions.oneProcessed(state, 'current', action);
            }

            case this.consts.getMe.SUCCESS: {
                return ReducerFunctions.oneSuccess(state, action.payload, 'current', this.entity, action);
            }

            case this.consts.getMe.FAILURE: {
                return ReducerFunctions.oneFailure(state, action.payload, 'current');
            }

            default: {
                return state;
            }
        }
    }
}

export default new UserReducer(INITIAL_STATE, ACTION_CONST, UserEntity, ['list']).reduce;