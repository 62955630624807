import { connect } from 'react-redux';

import HeaderComponent from '../../components/Header/HeaderComponent';

const mapStateToProps = (state) => {
    return {
        user: state.User.view.current.content,
    };
};

export default connect(mapStateToProps)(HeaderComponent);