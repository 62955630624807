import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AdminScreen from '../../screens/Admin/AdminScreen';
import {auth} from '../../routing/route';
import UserManager from '../../models/manager/UserManager';

const mapStateToProps = (state) => {
    return {
       //
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const item = {
        load: () => {
            UserManager.getMe((datum, success) => {
                if (!success) {
                    item.redirectToLogin();
                }
            });

        },
        redirectToLogin: () => {
            ownProps.history.push(auth.login.path);
        },
    };

    return item;
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminScreen));