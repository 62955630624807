import {connect} from 'react-redux';

import {admin} from '../../routing/route';
import AuthScreen from '../../screens/Auth/AuthScreen';
import AuthService from '../../services/Auth/AuthService';


const mapStateToProps = () => {
    return {
        //
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        login: (username, password) => {
            let callback = (datum, success) => {
                if (success) {
                    ownProps.history.push(admin.reservation.path);
                }
            };
            AuthService.loginUser(username, password, callback);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthScreen);