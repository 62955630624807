import objectHash from 'object-hash';
import {CLOSE_PANEL, OPEN_RIGHT_PANEL, OPEN_LEFT_PANEL} from '../actions/PanelAction';

const DEFAULT_CONTENT = [];

const INITIAL_STATE = {
    left: DEFAULT_CONTENT,
    right: DEFAULT_CONTENT,
};

let increment = 0;

const PanelReducer = (state = INITIAL_STATE, action = {}) => {

    switch (action.type) {
        case CLOSE_PANEL: {
            ['left', 'right'].forEach(position => {
                if (Array.isArray(state[position]) && state[position].length) {
                    state[position] = state[position].filter((panel) => (panel.id !== action.payload && panel.originId !== action.payload));
                }
            });

            return {...state};
        }

        case OPEN_RIGHT_PANEL:
        case OPEN_LEFT_PANEL: {
            const position = (action.type === OPEN_RIGHT_PANEL) ? 'right' : 'left';

            for (let i = 0; i < state[position].length; ++i) {
                if (state[position][i].label === action.payload.label) {
                    action.payload.parameters._id = state[position][i].parameters._id;
                    action.payload.parameters.key = state[position][i].parameters.key;

                    if (objectHash(state[position][i].parameters) === objectHash(action.payload.parameters)) {
                        if ((i+1) === state[position].length) {
                            return state;
                        }

                        let oldPanels = state[position].splice(i, 1);

                        if (oldPanels.length) {
                            action.payload = oldPanels[0];
                        }
                    } else {
                        state[position].splice(i, 1);
                        break;
                    }
                }
            }

            action.payload.id = increment++;
            state[position] = [...state[position], action.payload];

            return {...state};
        }

        default: {
            return state;
        }
    }
};

export default PanelReducer;