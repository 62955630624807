import Manager from './Manager';
import { actions } from '../../actions/CourseAction';

class CourseManager extends Manager {
    constructor(actions) {
        super(actions);

        this.searchFields = {
            ...this.searchFields,
        };
    }
}

export default new CourseManager(actions);

