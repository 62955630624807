import Api from './Api';
import {HTTP_DELETE, HTTP_GET, HTTP_POST, HTTP_PUT} from '../utils/api';
import pluralize from 'pluralize';
import _  from 'lodash';

export default class ModelApi extends Api {
    constructor(prefix) {
        super();

        this.prefix = pluralize(_.kebabCase(prefix));
    }

    get(parameters) {
        return this.callApi(HTTP_GET, this.prefix, parameters);
    }

    create(parameters) {
        return this.callApi(HTTP_POST, this.prefix, parameters);
    }

    update(id, parameters) {
        return this.callApi(HTTP_PUT, `${this.prefix}/${id}`, parameters);
    }

    delete(id, parameters) {
        return this.callApi(HTTP_DELETE, `${this.prefix}/${id}`, parameters);
    }
}