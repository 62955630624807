import ProductEntity from '../models/entity/ProductEntity';
import {ACTION_CONST} from '../actions/ProductAction';
import ModelReducer from './ModelReducer';

const INITIAL_STATE = {
    data: {},
    view: {
        list: {
            content: [],
            error: null,
            loading: true,
            page: 1,
        },
        create: {
            error: null,
            loading: false,
        },
        detail: {
            error: null,
            loading: false,
        },
    }
};

class ProductReducer extends ModelReducer {}

export default new ProductReducer(INITIAL_STATE, ACTION_CONST, ProductEntity, ['list']).reduce;