import Manager from './Manager';
import { actions } from '../../actions/ReservationAction';

class ReservationManager extends Manager {
    constructor(actions) {
        super(actions);

        this.searchFields = {
            ...this.searchFields,
        };
    }

    getAvailableSlots(parameters, callback) {
        return this.dispatch(
            this.actions.getAvailableSlots.INIT,
            () => this.actions.getAvailableSlots.ACTION(parameters),
            (content) => this.actions.getAvailableSlots.SUCCESS(content, {}),
            (error) => this.actions.getAvailableSlots.FAILURE(error, {}),
            callback,
        );
    }

    getCustomerPrice(customerId, parameters, callback) {
        return this.dispatch(
            this.actions.getCustomerPrice.INIT,
            () => this.actions.getCustomerPrice.ACTION(customerId, parameters),
            (content) => this.actions.getCustomerPrice.SUCCESS(content, {}),
            (error) => this.actions.getCustomerPrice.FAILURE(error, {}),
            callback,
        );
    }
}

export default new ReservationManager(actions);

