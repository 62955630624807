import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Spinner} from 'reactstrap';

class LoaderComponent extends Component {
    constructor(props) {
        super(props);

        this.getLoader = this.getLoader.bind(this);
    }

    render() {
        return (
            <Fragment>

                {
                    typeof this.props.children !== 'undefined' ? this.getWrappedLoader() : this.getLoader()
                }
                {
                    this.props.children
                }
            </Fragment>
        );
    }

    getLoader() {
        return(
            <div className={'loader-div'}>
                <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" />
            </div>
        );
    }

    getWrappedLoader() {
        return (
            <div className={'loader-overflow'}>
                {
                    this.getLoader()
                }
            </div>
        );
    }
}

LoaderComponent.propTypes = {
    className: PropTypes.string,
    children: PropTypes.object,
};

export default LoaderComponent;
