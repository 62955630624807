import Manager from './Manager';
import { actions } from '../../actions/PlanningAction';

class PlanningManager extends Manager {
    constructor(actions) {
        super(actions);

        this.searchFields = {
            ...this.searchFields,
        };
    }
}

export default new PlanningManager(actions);

