import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';

import ReservationStep1Screen from '../../screens/Reservation/ReservationStep1';
import ReservationManager from '../../models/manager/ReservationManager';
import {admin} from "../../routing/route";

const mapStateToProps = (state) => {
    return {
        isPending: state.Reservation.view.create.loading,
        user: state.User.view.current.content,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        createReservation: (params, callback) => {
          ReservationManager.create(params, callback);
        },
        getCustomerPrice: (customerId, params, callback) => {
            ReservationManager.getCustomerPrice(customerId, params, callback);
        },
        setReservationConfirmation: (params) => {
            ownProps.history.push({
                pathname: admin.reservationConfirmation.path,
                state: {params: params}
            });
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReservationStep1Screen));