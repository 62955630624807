import React, {Fragment} from 'react';
import moment from 'moment';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import {
    Button,
} from 'reactstrap';

import SlotReservation from '../../containers/Reservation/SlotReservationContainer';
import {
    DATE_DISPLAY_FORMAT,
    TIME_DISPLAY_FORMAT_HOUR_SEPARATOR
} from '../../utils/consts';

class ReservationAccordionSlotScreen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeSlot:   null,
        };

        this._displayReservationAction = this._displayReservationAction.bind(this);
    }

    _displayReservationAction(slot) {
        this.setState({activeSlot: slot});
    }

    render() {
        const {isActive, planning, product, slot, slotDay} = this.props;

        const formattedTime = moment(slotDay + slot.slotHour, DATE_DISPLAY_FORMAT + 'HH:mm').format(TIME_DISPLAY_FORMAT_HOUR_SEPARATOR);

        return (
            <Fragment>
                <div>
                    <Button className={`slot ${isActive ? 'btn-active' : ''}`}
                            color={'light'}
                            onClick={() => this.props.setActive(slot)}
                    >
                        {
                            slot.hasFriends && (slot.planning_id === planning.id) &&
                            <i className={'fa fa-user mr-2'} />
                        }
                        {`${formattedTime} (${slot.availableSlots} ${pluralize('slot', slot.availableSlots)})`}
                    </Button>
                </div>

                {isActive &&
                <SlotReservation planning={planning}
                                 product={product}
                                 slot={slot}
                                 slotDay={slotDay}
                />}
            </Fragment>
        );
    }
}

ReservationAccordionSlotScreen.defaultProps = {
    isActive: false,
};

ReservationAccordionSlotScreen.propTypes = {
    isActive    : PropTypes.bool,
    planning    : PropTypes.object.isRequired,
    product     : PropTypes.object.isRequired,
    setActive   : PropTypes.func.isRequired,
    slot        : PropTypes.object.isRequired,
    slotDay     : PropTypes.string.isRequired,
};

export default ReservationAccordionSlotScreen;