import React from 'react';

export const ERROR_INPUT_NEEDED = 'Ce champs est requis';

/**
 * Return the error html element for an input
 * @return {*}
 */
export function getSpanError(errorString=null) {
    return <div className="mt-2 error">{errorString ?? ERROR_INPUT_NEEDED}</div>;
}