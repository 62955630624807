import store from '../../store';

class PanelService {
    closePanel(id, status) {
        // TODO : Status is currently forced to "true" to avoid close & reload issues
        status = true;
        const state = store.getState();

        ['left', 'right'].forEach(position => {
            if (Array.isArray(state.Panel[position]) && state.Panel[position].length) {
                state.Panel[position].forEach(panel => {
                    if (panel.id === id || panel.originId === id) {
                        _callbackClose(panel, status);
                    }
                });
            }
        });
    }

    closeAllLeftPanel() {
        const state = store.getState();
        if (Array.isArray(state.Panel.left) && state.Panel.left.length) {
            state.Panel.left.forEach(panel => {
                _callbackClose(panel, false);
            });
        }
    }

    closeAllRightPanel() {
        const state = store.getState();
        if (Array.isArray(state.Panel.right) && state.Panel.right.length) {
            state.Panel.right.forEach(panel => {
                _callbackClose(panel, false);
            });
        }
    }
}

function _callbackClose(panel, status) {
    if (panel.callbacks && panel.callbacks.onClose) {
        panel.callbacks.onClose(status);
    }
}

export default new PanelService();