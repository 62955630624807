import { connect } from 'react-redux';

import CustomerScreen from '../../screens/Customer/CustomerScreen';
import CustomerManager from '../../models/manager/CustomerManager';

const mapStateToProps = (state) => {
    return {
        isPending: state.Customer.view.detail.loading,
    };
};

const mapDispatchToProps = () => {
    return {
        updatePassword: (parameters, callback) => {
            CustomerManager.updatePassword(parameters, callback);
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerScreen);