import React, {Fragment} from 'react';
import moment from 'moment';

import Loader from '../../components/Loader/LoaderComponent';
import {TIME_DISPLAY_FORMAT_HOUR_SEPARATOR} from '../../utils/consts';

class ReservationConfirmationScreen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            params: null,
        };
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({params: this.props.location.state.params});
        }
    }

    render() {
        const {params} = this.state;

        if (!params) {
            return (
                <Loader />
            );
        }

        let title = null;
        let content = null;
        let dateTimeString = `le départ du ${params.slotDay} à ${moment(params.slotHour, 'HH:mm').format(TIME_DISPLAY_FORMAT_HOUR_SEPARATOR)}`;

        let commonDiv = (
            <Fragment>
                <div>{`Parcours : ${params.planning_label}`}</div>
                <div>{`Nombre de trous : ${params.product_label}`}</div>
            </Fragment>
        );

        if (params.success) {
            title = 'Confirmation de votre réservation';
            content = (
                <Fragment>
                    <div className={'header'}>{`Votre réservation pour ${dateTimeString} est confirmée.`}</div>
                    {commonDiv}
                    <div className={'footer'}>{`Un mail de confirmation vous a été envoyé.`}</div>
                </Fragment>
            ) ;
        } else {
            title = 'Votre réservation n\'a pas été enregistrée';
            content = (
                <Fragment>
                    <div className={'header'}>{`Votre réservation pour ${dateTimeString} n\'a pas été enregistrée.`}</div>
                    {commonDiv}
                    <div className={'footer'}>{'Veuillez contacter votre Golf.'}</div>
                </Fragment>
            ) ;
        }

        return (
            <div className={'reservation-confirmation'}>
                <h2 className={'col-sm-12'}>{title}</h2>
                <div className={'response'} style={{fontSize: 24}}>
                {content}
                </div>
            </div>
        );
    }
}

export default ReservationConfirmationScreen;