export const admin = {
    reservation: {
        path: '/reservations',
        name: 'Réserver'
    },
    reservationStep1: {
        path: '/reservation-step-one',
        name: 'reservationStepOne'
    },
    reservationConfirmation: {
        path: '/reservation-confirmation',
        name: 'reservationConfirmation'
    },
    friend: {
        path: '/friends',
        name: 'Mes amis'
    },
    customer: {
        path: '/customer',
        name: 'Mon profil'
    },
};

export const auth = {
    login : {
        path: '/login',
        name: 'Login'
    },
};