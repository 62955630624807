import {admin} from '../../routing/route';

export default {
    items: [
        {
            name: admin.reservation.name,
            url: admin.reservation.path,
            icon: 'fa fa-golf-ball',
        },
        {
            name: admin.friend.name,
            url: admin.friend.path,
            icon: 'fa fa-users',
        },
    ]
};
