import { loginUser, logoutUser } from '../../actions/AuthAction';
import DispatcherService from '../Dispatcher/DispatcherService';

class AuthService {
    static loginUser(username, password, callback) {
        return DispatcherService.classicDispatch(
            loginUser.INIT,
            () => loginUser.ACTION(username, password),
            (content) => loginUser.SUCCESS(content, {}),
            (error) => loginUser.FAILURE(error, {}),
            callback
        );
    }

    static logoutUser(callback) {
        logoutUser.ACTION();
        callback();
    }
}

export default AuthService;