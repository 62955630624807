import axios from 'axios';
import auth from '../utils/auth.js';
import Api from "./Api";
import {AXIOS_CONF, ROOT_URL} from "../utils/api";

let tokenUrl = ROOT_URL + 'oauth/token';
let CLIENT_ID = process.env.API_CLIENT_ID;
let CLIENT_SECRET = process.env.API_TOKEN;
let PROVIDER = process.env.PROVIDER;

class AuthApi extends Api {
    static logoutApi() {
        return auth.clearToken();
    }

    static loginApi(params = {}) {
        let promise;
        let that = this;

        if (typeof params.username === 'undefined') {
            promise = new Promise(function (resolve, reject) {
                reject({error: true, payload: 'Invalid login'});
            });

            return promise;
        }

        if (typeof params.password === 'undefined') {
            promise = new Promise(function (resolve, reject) {
                reject({error: true, payload: 'Invalid password'});
            });

            return promise;
        }

        params['grant_type'] = 'password';
        params['client_id'] = CLIENT_ID;
        params['client_secret'] = CLIENT_SECRET;
        params['provider'] = PROVIDER;

        promise = axios.post(tokenUrl, params, AXIOS_CONF);

        promise.then((response) => {
            if (!response.error) {
                auth.setAccessToken(response.data.access_token);
                auth.setRefreshToken(response.data.refresh_token);
            } else {
                that.logoutApi();
            }
        });

        return promise;
    }
}

export default AuthApi;