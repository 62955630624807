import React, { Component, Fragment } from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import { Provider } from 'react-redux';
import {ToastContainer} from 'react-toastify';
import moment from 'moment';
import store from '../../store';
import {auth} from '../../routing/route';

// Components
import PrivateRoute from '../../components/PrivateRoute/PrivateRouteComponent';
import AuthContainer from '../../containers/Auth/AuthContainer';
import AdminContainer from '../../containers/Admin/AdminContainer';

// Style
import '@phoenix-fr/fontawesome-pro-5/css/all.min.css';
import 'simple-line-icons/css/simple-line-icons.css';
import 'react-datetime/css/react-datetime.css';
import 'ladda/dist/ladda-themeless.min.css';
import '../../../scss/style.scss';

moment().locale('fr');

class AppScreen extends Component {
    render() {
        return (
            <Provider store={store}>
                <HashRouter>
                    <Fragment>
                        <ToastContainer draggable={false} />

                        <Switch>
                            <Route exact path={auth.login.path} name={auth.login.name} component={AuthContainer} />
                            <PrivateRoute path="/" name="Admin" component={AdminContainer} />
                        </Switch>
                    </Fragment>
                </HashRouter>
            </Provider>
        );
    }
}

export default AppScreen;