import ConfigEntity from '../models/entity/ConfigEntity';
import {ACTION_CONST} from '../actions/ConfigAction';
import ModelReducer from './ModelReducer';

const INITIAL_STATE = {
    data: {},
    view: {
        list: {
            content: [],
            error: null,
            loading: true,
            page: 1,
        },
        create: {
            error: null,
            loading: false,
        },
        detail: {
            error: null,
            loading: false,
        },
    }
};

class ConfigReducer extends ModelReducer {}

export default new ConfigReducer(INITIAL_STATE, ACTION_CONST, ConfigEntity, ['list']).reduce;
