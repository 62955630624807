import ReservationApi from '../api/ReservationApi';
import {ModelActions, ModelActionsConst} from './ModelAction';
import {ClassicActions, ClassicConstantList} from './classic';

export const ACTION_CONST = {
    ...ModelActionsConst('reservation'),
    getAvailableSlots: ClassicConstantList('GET_AVAILABLE_SLOTS'),
    getCustomerPrice: ClassicConstantList('GET_PRICE'),
};

export const actions = {
    ...ModelActions(ACTION_CONST, ReservationApi),

    getAvailableSlots: new ClassicActions(ACTION_CONST.getAvailableSlots, {
        action: (parameters) => {
            return ReservationApi.getAvailableSlots(parameters);
        }
    }),

    getCustomerPrice: new ClassicActions(ACTION_CONST.getCustomerPrice, {
        action: (customerId, parameters) => {
            return ReservationApi.getCustomerPrice(customerId, parameters);
        }
    }),
};