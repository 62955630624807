import React, {Component, Fragment} from 'react';
import PanelListComponent from './PanelListComponent';
import PropTypes from 'prop-types';

class PanelManagerComponent extends Component {
    shouldComponentUpdate(nextProps) {
        return (nextProps.aRightPanel.length + nextProps.aLeftPanel.length) !== (this.props.aRightPanel.length + this.props.aLeftPanel.length);
    }

    render() {
        const { aRightPanel, aLeftPanel } = this.props;

        return (
            <Fragment>
                <PanelListComponent left aPanel={aLeftPanel} />
                <PanelListComponent right aPanel={aRightPanel} />
            </Fragment>
        );
    }
}

PanelManagerComponent.propTypes = {
    aRightPanel: PropTypes.array,
    aLeftPanel: PropTypes.array,
};

export default PanelManagerComponent;
