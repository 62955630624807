import {connect} from 'react-redux';
import moment    from 'moment';

import ReservationScreen  from '../../screens/Reservation/ReservationScreen';
import ReservationManager from '../../models/manager/ReservationManager';
import CourseManager      from '../../models/manager/CourseManager';
import CustomerManager    from '../../models/manager/CustomerManager';
import PlanningManager    from '../../models/manager/PlanningManager';
import ProductManager     from '../../models/manager/ProductManager';
import {
    DATE_REQUEST_FORMAT,
    PRODUCT_TYPE
}                         from '../../utils/consts';
import ConfigManager      from '../../models/manager/ConfigManager';

const mapStateToProps = (state) => {
    return {
        availableSlots     : Object.values(state.Reservation.view.list.content),
        configData         : state.Config.view.list.content,
        courses            : state.Course.view.list.content,
        friendsReservations: state.Customer.view.friendsReservations.content,
        plannings          : state.Planning.view.list.content,
        products           : state.Product.view.list.content,
    };
};

const mapDispatchToProps = () => {
    return {
        load           : () => {
            CourseManager.getAll();
            PlanningManager.getAll();
            const configParameters = {
                filter: [
                    {
                        column  : 'label',
                        operator: 'IN',
                        value   : ['online_max_slots', 'online_display_today', 'online_displayed_days', 'online_multi_customer'],
                    }
                ]
            };
            ConfigManager.search(configParameters);

            const searchParameters = {
                filter: [
                    {
                        column  : 'const_type',
                        operator: 'IN',
                        value   : [
                            PRODUCT_TYPE.TYPE_GREENFEE9.const_type,
                            PRODUCT_TYPE.TYPE_GREENFEE18.const_type,
                        ],
                    }
                ]
            };
            ProductManager.search(searchParameters);
        },
        loadReservation: (dateStart, dateStop, planning_id, callback) => {
            const date_start = moment(dateStart).format(DATE_REQUEST_FORMAT);

            CustomerManager.getAll();
            CustomerManager.getFriendsReservations({});
            ReservationManager.getAvailableSlots({
                date_start  : date_start,
                date_stop   : dateStop.format(DATE_REQUEST_FORMAT),
                planning_ids: [planning_id]
            }, callback);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationScreen);
