export const DATE_DISPLAY_FORMAT = 'DD/MM/YYYY';
export const TIME_DISPLAY_FORMAT = 'HH:mm';
export const TIME_DISPLAY_FORMAT_HOUR_SEPARATOR = 'HH[h]mm';
export const TIME_DISPLAY_FORMAT_HOUR = 'HH';
export const TIME_DISPLAY_FORMAT_MINUTE = 'mm';
export const DATETIME_DISPLAY_FORMAT = 'DD/MM/YYYY HH:mm';
export const DATETIME_DISPLAY_SHORT_FORMAT = 'DD/MM/YYYY';
export const DATETIME_REQUEST_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_REQUEST_FORMAT = 'YYYY-MM-DD';

export const GET_ME_INTERVAL_DELAY = 30000;

export const MODELS = {
    CONFIG      : 'config',
    COURSE      : 'course',
    CUSTOMER    : 'customer',
    FRIEND      : 'friend',
    PLANNING    : 'planning',
    PRODUCT     : 'product',
    RESERVATION : 'reservation',
    USER        : 'user',
};

export const SUFFIX_LIST_CSS = '__list';
export const SUFFIX_HEADER_CSS = '__header';
export const SUFFIX_ELEMENT_CSS = '__element';
export const SUFFIX_INPUT_CSS = '__input';
export const SUFFIX_TRASH_CSS = '__trash';

export const COURSE_SIZE = {NINE: 9, EIGHTEEN: 18};

export const PRODUCT_TYPE = {
    TYPE_GREENFEE9      : {const_type: 3, label: 'GreenFee 9'},
    TYPE_GREENFEE18     : {const_type: 4, label: 'GreenFee 18'},
};

export const CONFIG = {
    ONLINE_DISPLAY_TODAY   : 'online_display_today',
    ONLINE_DISPLAYED_DAYS  : 'online_displayed_days',
    ONLINE_MAX_SLOTS       : 'online_max_slots',
    ONLINE_MULTI_CUSTOMERS : 'online_multi_customer',
};

export const MAX_SLOTS = 4;
