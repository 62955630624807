import CustomerApi from '../api/CustomerApi';
import {ModelActions, ModelActionsConst} from './ModelAction';
import {ClassicActions, ClassicConstantList} from './classic';

export const ACTION_CONST = {
    ...ModelActionsConst('customer'),
    addFriend               : ClassicConstantList('ADD_FRIEND'),
    deleteFriend            : ClassicConstantList('DELETE_FRIEND'),
    getFriends              : ClassicConstantList('GET_FRIENDS'),
    getFriendsReservations  : ClassicConstantList('GET_FRIENDS_RESERVATIONS'),
    updatePassword          : ClassicConstantList('UPDATE_PASSWORD'),
};

export const actions = {
    ...ModelActions(ACTION_CONST, CustomerApi),

    addFriend: new ClassicActions(ACTION_CONST.addFriend, {
        action: (parameters) => {
            return CustomerApi.addFriend(parameters);
        }
    }),

    deleteFriend: new ClassicActions(ACTION_CONST.deleteFriend, {
        action: (friendId) => {
            return CustomerApi.deleteFriend(friendId);
        }
    }),

    getFriends: new ClassicActions(ACTION_CONST.getFriends, {
        action: (parameters) => {
            return CustomerApi.getFriends(parameters);
        }
    }),

    getFriendsReservations: new ClassicActions(ACTION_CONST.getFriendsReservations, {
        action: (parameters) => {
            return CustomerApi.getFriendsReservations(parameters);
        }
    }),

    updatePassword: new ClassicActions(ACTION_CONST.updatePassword, {
        action: (parameters) => {
            return CustomerApi.updatePassword(parameters);
        }
    })
};