import {connect} from 'react-redux';

import FriendScreen     from '../../screens/Friend/FriendScreen';
import CustomerManager  from "../../models/manager/CustomerManager";
import UserManager      from '../../models/manager/UserManager';

const mapStateToProps = (state) => {
    return {
        currentUser     : state.User.view.current.content,
        customerError   : state.Customer.view.detail.error,
        customer        : state.Customer.view.detail.content[0],
    };
};

const mapDispatchToProps = () => {
    return {
        loadCurrentCustomer: () => {
                UserManager.getMe();
        },
        addFriend: (parameters, callback) => {
            CustomerManager.addFriend(parameters, callback);
        },
        deleteFriend: (friendId, callback) => {
            CustomerManager.deleteFriend(friendId, callback);
        },
        searchCustomer: (customerId) => {
            CustomerManager.search({id: customerId});
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FriendScreen);