import {forOwn, strncmp, studlyCase} from '../../utils/functions';
import _ from 'lodash';
import moment from 'moment';

export default class Entity
{
    constructor (attributes) {
        this.fill(
            {
                ...attributes,
                __model: this.modelKey()
            }
        );
    }

    /**
     * The attributes that should be mutated to dates.
     *
     * @returns {Array}
     */
    dates() {
        return [];
    }

    /**
     * The accessors to append to the model's array form.
     *
     * @returns {Array}
     */
    appends() {
        return [];
    }

    /**
     * The attributes that should be cast to native types.
     *
     * @returns {{}}
     */
    casts() {
        return {};
    }

    /**
     *
     * @param attributes
     * @returns {Entity}
     */
    fill (attributes) {
        this.setAttributes(attributes);

        this.wrapRelationships();

        const $appends = this.getAppends();

        if ($appends.length > 0) {
            forOwn($appends, (key) => {
                this[key] = this.mutateAttribute(key);
            });
        }

        return this;
    }

    modelKey() {
        return '';
    }

    /**
     * @param attributes
     * @returns {*}
     */
    make (attributes = {}) {
        return Array.isArray(attributes)
            ? attributes.map(nested => new this.constructor(nested))
            : new this.constructor(attributes);
    }

    /**
     *
     * @returns {*}
     */
    clone () {
        return this.make({ ...this.getAttributes() });
    }

    /**
     * Get all of the current attributes on the model.
     *
     * @returns {{}}
     */
    getAttributes () {
        return { ...this };
    }

    /**
     * Set a given attribute on the model.
     *
     * @param key
     * @param value
     * @returns {*}
     */
    setAttribute (key, value) {
        // First we will check for the presence of a mutator for the set operation
        // which simply lets the developers tweak the attribute as it is set on the model
        if (this.hasSetMutator(key)) {
            return this.setMutatedAttributeValue(key, value);
        }

        // If an attribute is listed as a "date", we'll convert it from a DateTime
        // instance into a form proper for storage on the database tables using
        // the connection grammar's date format. We will auto set the values.
        else if (value && this.isDateAttribute(key)) {
            value = moment(value);
        }

        this[key] = value;

        return this;
    }

    /**
     * Set attributes on the model.
     *
     * @param attributes
     */
    setAttributes (attributes) {
        forOwn(attributes, (value, key) => {
            this.setAttribute(key, value);
        });
    }

    /**
     * Get the attributes that should be converted to dates.
     *
     * @returns {Array}
     */
    getDates() {
        const defaults = ['created_at', 'updated_at'];

        return _.union(this.dates(), defaults);
    }

    /**
     * Get all of the appendable values
     *
     * @returns {Array}
     */
    getAppends() {
        return this.appends();
    }

    /**
     * Get the casts array.
     *
     * @returns {{}}
     */
    getCasts() {
        return this.casts();
    }

    /**
     * Determine if the given attribute is a date or date castable.
     *
     * @param key
     * @returns {boolean}
     */
    isDateAttribute(key) {
        return this.getDates().indexOf(key) > -1 || this.isDateCastable(key);
    }

    /**
     * Determine whether a value is Date / DateTime castable for inbound manipulation.
     *
     * @param key
     * @returns {boolean}
     */
    isDateCastable(key) {
        return this.hasCast(key, ['date', 'datetime']);
    }

    /**
     * Determine if the cast type is a custom date time cast.
     *
     * @param cast
     * @returns {boolean}
     */
    isCustomDateTimeCast(cast) {
        return strncmp(cast, 'date:', 5) === 0 || strncmp(cast, 'datetime:', 9) === 0;
    }

    /**
     * Determine if the cast type is a decimal cast.
     *
     * @param cast
     * @returns {boolean}
     */
    isDecimalCast(cast) {
        return strncmp(cast, 'decimal:', 8) === 0;
    }

    /**
     * Determine whether an attribute should be cast to a native type.
     *
     * @param key
     * @param types
     * @returns {boolean}
     */
    hasCast(key, types = null) {
        if (this.getCasts().hasOwnProperty(key)) {
            return types ? types.indexOf(this.getCastType(key)) > -1 : true;
        }

        return false;
    }

    /**
     * Determine if a set mutator exists for an attribute.
     *
     * @param key
     * @returns {boolean}
     */
    hasSetMutator(key) {
        return (typeof this[`set${studlyCase(key)}Attribute`] === 'function');
    }

    /**
     * Get the value of an attribute using its mutator.
     *
     * @param key
     */
    mutateAttribute(key) {
        return this[`get${studlyCase(key)}Attribute`]();
    }

    /**
     * Set the value of an attribute using its mutator.
     *
     * @param key
     * @param value
     */
    setMutatedAttributeValue(key, value)
    {
        return this[`set${studlyCase(key)}Attribute`](value);
    }

    /**
     * Get the type of cast for a model attribute.
     *
     * @param key
     * @returns {string}
     */
    getCastType(key)
    {
        if (this.isCustomDateTimeCast(this.getCasts()[key])) {
            return 'custom_datetime';
        }

        if (this.isDecimalCast(this.getCasts()[key])) {
            return 'decimal';
        }

        return this.getCasts()[key].toLowerCase().trim();
    }

    relationships () {
        return {};
    }

    addParentRelationship (parent, relationship) {
        this._parent = [parent, relationship];
        return this;
    }

    wrapRelationship (attributes, model, parent, relationship) {
        let result;

        if (typeof model === 'object') {
            result = new model[parent[relationship + '_type']](attributes);
        } else {
            result = new model(attributes);
        }

        result.addParentRelationship(parent, relationship);

        return result;
    }

    wrapRelationships () {
        let attributes = this.getAttributes() ?? {};

        forOwn(this.relationships(), (model, key) => {
            let value = attributes.getValueFromKey(key);

            if (! value) {
                return;
            }

            let valueCasted;

            if (Array.isArray(value)) {
                valueCasted = value.map(nested => this.wrapRelationship(nested, model, this, key));
            } else {
                valueCasted = this.wrapRelationship(value, model, this, key);
            }

            attributes.setValueFromKey(key, valueCasted);
        });

        this.setAttributes(attributes);
    }
}
