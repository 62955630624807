const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';

const auth = {
    /**
     * Remove an item from the used storage
     * @param  {String} key [description]
     */
    clear(key) {
        if (localStorage && localStorage.getItem(key)) {
            return localStorage.removeItem(key);
        }

        return null;
    },

    clearToken() {
        this.clearAccessToken();
        this.clearRefreshToken();

        return true;
    },

    clearAccessToken() {
        return auth.clear(ACCESS_TOKEN_KEY);
    },

    clearRefreshToken() {
        return auth.clear(REFRESH_TOKEN_KEY);
    },

    /**
     * Returns data from storage
     * @param  {String} key Item to get from the storage
     * @return {String|Object}     Data from the storage
     */
    get(key) {
        if (localStorage && localStorage.getItem(key)) {
            return JSON.parse(localStorage.getItem(key)) ?? null;
        }

        return null;
    },

    getAccessToken() {
        return auth.get(ACCESS_TOKEN_KEY);
    },

    getRefreshToken() {
        return auth.get(REFRESH_TOKEN_KEY);
    },

    /**
     * Set data in storage
     * @param {String|Object}  value    The data to store
     * @param {String}  key
     */
    set(value, key) {
        if (!value) {
            return null;
        }

        return localStorage.setItem(key, JSON.stringify(value));
    },

    setAccessToken(value = '') {
        return auth.set(value, ACCESS_TOKEN_KEY);
    },

    setRefreshToken(value = '') {
        return auth.set(value, REFRESH_TOKEN_KEY);
    },
};

export default auth;
