import React     from 'react';
import moment    from 'moment';
import PropTypes from 'prop-types';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

import ReservationAccordionHourScreen from './ReservationAccordionHourScreen';
import {
    DATE_DISPLAY_FORMAT, MAX_SLOTS,
    TIME_DISPLAY_FORMAT,
    TIME_DISPLAY_FORMAT_HOUR
} from '../../utils/consts';

class ReservationAccordionScreen extends React.Component {

    constructor(props) {
        super(props);

        this._computeArray = this._computeArray.bind(this);
        this.filterObjectByDate = this.filterObjectByDate.bind(this);
    }

    filterObjectByDate(now, objectArray) {
        return objectArray.reduce((acc, slot) => {
            const ObjectDate = moment(slot.date_start).clone();
            if (ObjectDate.isSame(now) || ObjectDate.isAfter(now)) {
                const diff = MAX_SLOTS - this.props.allowedSlots;
                slot.limited_available = Math.max(slot.available - diff, 0);

                if (slot.limited_available > 0) {
                    acc.push(slot);
                }
            }
            return acc;
        }, []);
    }

    _computeArray() {
        const {availableSlots, friendsReservations} = this.props;

        const now = moment();

        //Delete useless available slots (prior to now)
        const filteredFriendsReservations = this.filterObjectByDate(now, friendsReservations);

        // Compute friends by date / slot
        const friendsReservationArray = filteredFriendsReservations.reduce((acc, friendReservation) => {
            const headingDate = moment(friendReservation.date_start).format(DATE_DISPLAY_FORMAT);
            const reservationHourMinute = moment(friendReservation.date_start).format(TIME_DISPLAY_FORMAT);

            if ( ! acc[headingDate]) {
                acc[headingDate] = {};
            }

            if ( ! acc[headingDate][reservationHourMinute]) {
                acc[headingDate][reservationHourMinute] = {
                    customer_ids: [],
                    planning_id : friendReservation.planning_id,
                };
            }

            if (acc[headingDate][reservationHourMinute].customer_ids.indexOf(friendReservation.id) === -1) {
                acc[headingDate][reservationHourMinute].customer_ids.push(friendReservation.id);
            }

            return acc;
        }, []);

        //Delete useless available slots (prior to now)
        const filteredFAvailableSlots = this.filterObjectByDate(now, availableSlots);

        const reservationArray = filteredFAvailableSlots.reduce((acc, reservation) => {

            const headingDate = moment(reservation.date_start).format(DATE_DISPLAY_FORMAT);
            const slotHour = moment(reservation.date_start).clone().minutes(0).format(TIME_DISPLAY_FORMAT_HOUR);
            const slotHourMinute = moment(reservation.date_start).format(TIME_DISPLAY_FORMAT);

            // Sort reservation by dd/mm/yyyy
            if ( ! acc[headingDate]) {
                acc[headingDate] = {
                    availableSlots: reservation.limited_available,
                    dayDate       : headingDate,
                    hours         : [],
                };
            } else {
                acc[headingDate].availableSlots += reservation.limited_available;
            }

            if ( ! acc[headingDate].hours[slotHour]) {

                acc[headingDate].hours[slotHour] = {
                    availableSlots: reservation.limited_available,
                    hasFriends    : false,
                    planning_id   : null,
                    slotHour      : slotHour,
                    slots         : [],
                };
            } else {
                acc[headingDate].hours[slotHour].availableSlots += reservation.limited_available;
            }

            if ( ! acc[headingDate].hours[slotHour].slots[slotHourMinute]) {

                const hasFriends = typeof friendsReservationArray[headingDate] !== 'undefined' && typeof friendsReservationArray[headingDate][slotHourMinute] !== 'undefined';

                acc[headingDate].hours[slotHour].slots[slotHourMinute] = {
                    availableSlots: reservation.limited_available,
                    customer_ids  : hasFriends ? friendsReservationArray[headingDate][slotHourMinute].customer_ids : [],
                    hasFriends    : hasFriends,
                    planning_id   : hasFriends ? friendsReservationArray[headingDate][slotHourMinute].planning_id : null,
                    slotHour      : slotHourMinute,
                };

                if (hasFriends) {
                    acc[headingDate].hours[slotHour].hasFriends = true;
                    acc[headingDate].hours[slotHour].planning_id = friendsReservationArray[headingDate][slotHourMinute].planning_id;
                }
            }

            return acc;
        }, []);

        const finalReservationArray = Object.keys(reservationArray).reduce((acc, resaKey) => {

            Object.keys(reservationArray[resaKey].hours).forEach((hour) => {

                reservationArray[resaKey].hours[hour].slots = Object.values(reservationArray[resaKey].hours[hour].slots);
            });

            reservationArray[resaKey].hours = Object.values(reservationArray[resaKey].hours);
            acc.push(reservationArray[resaKey]);
            return acc;
        }, []);

        return Object.values(finalReservationArray);
    }


    render() {
        const {
                  availableSlots,
                  friendsReservations,
                  planning,
                  product,
              } = this.props;

        if ( ! availableSlots || availableSlots.length === 0 || ! friendsReservations) {
            return <div />;
        }

        const computedAvailableSlots = this._computeArray();

        return (
            <Accordion allowZeroExpanded>
                {
                    computedAvailableSlots
                        .sort((a, b) => moment(a.dayDate).diff(moment(b.dayDate)))
                        .map((slotByDay) => {
                            const availableSlotsString = slotByDay.availableSlots === 1 ? 'slot disponible' : 'slots disponibles';

                            return (
                                <AccordionItem key={slotByDay.dayDate}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>{`${slotByDay.dayDate} (${slotByDay.availableSlots} ${availableSlotsString})`}</AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <Accordion allowZeroExpanded>
                                            {
                                                slotByDay.hours
                                                    .sort((a, b) => moment(a.slotHour, 'HH').isBefore(moment(b.slotHour, 'HH')) ? -1 : 1)
                                                    .map((slotByHour) => {
                                                        return <ReservationAccordionHourScreen key={slotByHour.slotHour}
                                                                                               availableSlots={slotByHour}
                                                                                               slotDay={slotByDay.dayDate}
                                                                                               planning={planning}
                                                                                               product={product}
                                                        />;
                                                    })

                                            }
                                        </Accordion>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            );
                        })
                }
            </Accordion>
        );
    }
}

ReservationAccordionScreen.propTypes = {
    availableSlots     : PropTypes.array.isRequired,
    allowedSlots       : PropTypes.number.isRequired,
    friendsReservations: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    planning           : PropTypes.object.isRequired,
    product            : PropTypes.object.isRequired,
};

export default ReservationAccordionScreen;
